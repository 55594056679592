import { themes } from "common/styles/theme";
import styled from "styled-components";

// import { themes } from "common/styles/theme";

export const AccountMain = styled.div`
  min-height: 80vh;
`;

export const Top = {
  main: styled.div`
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.16);
    border-radius: 18px;
    margin: 50px auto;
  `,
  content: styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-evenly;
    justify-items: center;
  `,
  coin: styled.div`
    flex-direction: column;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    > p {
      font-weight: 500;
    }
  `,
  title: styled.div`
    display: flex;
    justify-content: space-between;
    padding: 15px;
    background: ${themes.colors.black};
    border-radius: 20px 20px 0px 0px;
    color: ${themes.colors.white};
  `,
  subTitle: styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    > span {
      font-weight: 600;
      font-family: sans-serif;
    }
  `,
  context: styled.div`
    padding: 20px 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    .promotion {
      background: linear-gradient(to right, #00d768 0%, #01fcff 100%);
    }
    @keyframes glowing {
      0% {
        background-position: 0 0;
      }
      50% {
        background-position: 400% 0;
      }
      100% {
        background-position: 0 0;
      }
    }
  `,
  item: styled.div`
    background: ${themes.colors.linerAccount};
    border-radius: 15px;
    ${themes.mixin.flexDirection()};
    max-width: 300px;
    width: 100%;
  `,
  image: styled.div`
    font-weight: 700;
    padding: 0 5px;
    margin-top: 10px;
    z-index: 2;
    > img {
      width: 50px;
    }
    > p {
      height: 50px;
      color: white;
    }
  `,
  // text: styled.div`
  //     padding: 30px 0;
  //     ${themes.mixin.flexCenter()};
  //     flex-direction:column;
  //     width:100%;
  //     height:100%;
  //     background:${themes.colors.white};
  //     border-radius:15px;
  //     margin-top:-20px;
  //     z-index:1;
  //     >p{
  //         color:${themes.colors.blueText};
  //         font-weight:500;
  //     }
  //     >span{
  //         font-weight:700;
  //         color:${themes.colors.primary};
  //     }
  // `,
  text: styled.div`
    padding: 30px 0;
    ${themes.mixin.flexCenter()};
    flex-direction: row;
    width: 100%;
    height: 100%;
    text-align: center;
    background: ${themes.colors.white};
    border-radius: 15px;
    margin-top: -20px;
    z-index: 1;
    > p {
      color: ${themes.colors.blueText};
      font-weight: 500;
    }
    > span {
      font-weight: 700;
      color: ${themes.colors.primary};
    }
  `,
};

export const Historys = {
  title: styled.div`
    text-align: center;
    padding: 15px;
    font-weight: bold;
  `,
  main: styled.div`
    min-width: 750px;
    background: ${themes.colors.opacity};
    box-shadow: 0px 10px 28px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    margin-bottom: 50px;
    overflow: hidden;
  `,

  price: styled.div`
    strong {
      color: ${themes.colors.orange};
    }
  `,
  context: styled.div`
    color: ${themes.colors.white};
  `,
  contextTitle: styled.div``,
  itemTitle: styled.div`
    ${themes.mixin.flexColCenter()};
    padding: 15px;
    text-align: center;
    background: ${themes.colors.linearCard};
    font-size: 14px;
    border-radius: 18px;
  `,
  item: styled.div`
    display: flex;
    padding: 15px 10px;
    text-align: center;
    font-size: 14px;
    &:nth-child(odd) {
      background: ${themes.colors.history};
    }
    > div {
      ${themes.mixin.flexCenter()};
      flex-direction: column;
      > strong {
        color: ${themes.colors.orange};
      }
    }
  `,
};
