import { createSlice } from "@reduxjs/toolkit";
import produce from "immer";

const initialState = {
  loading: false,
  openDrawer: false,
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setLoading: (state, { loading }) =>
      produce(state, (draft) => {
        draft.loading = loading;
      }),
    openDrawer: (state, { isOpen }) =>
      produce(state, (draft) => {
        draft.isOpenDrawer = isOpen;
      }),
  },
});

const { reducer, actions } = commonSlice;
export const { setLoading } = actions;
export default reducer;
