import { lazy } from "react";
import { PATH } from "routes/PATH";

const Maps = lazy(() => import("pages/Maps"));
const MapsDetails = lazy(() => import("pages/MapsDetails"));
const AppMintNFT = lazy(() => import("pages/MintNFTApp"));

export const maps = [
  {
    name: "Maps",
    path: PATH.MAPS.MAPS,
    isPrivate: false,
    component: <Maps />,
  },
  {
    name: "Maps Detail",
    path: PATH.MAPS.MAPS_DETAIL,
    isPrivate: false,
    component: <MapsDetails />,
  },
  {
    name: "Mint NFT",
    path: PATH.MAPS.APP_MINT_NFT,
    isPrivate: false,
    component: <AppMintNFT />,
  },
];
