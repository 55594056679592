import { Outlet } from "react-router-dom";

const Dashboard = () => {
  return (
    <div>
      <div
        style={{
          background: "#08021c",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Outlet />
      </div>
    </div>
  );
};
export default Dashboard;
