import React, { useRef, useState } from "react";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { TextField } from "@mui/material";

import { GetValue2fa } from "redux/slices/auth";
import ReCAPTCHA from "react-google-recaptcha";
import { GetTokenRep } from "redux/slices/recaptchaF2A";


const textFiled = {
    background: "white",
    borderRadius: "5px",
};
export default function Recaptcha() {

    let { value2fa } = useSelector(state => state.auth);
    let reRef = useRef();
    // let reCaptcha = useRef();
    // const { getprofile } = useSelector((state) => state.auth);
    // const twofa = getprofile?.enabled_twofa;
    let dispatch = useDispatch();

    const [token, setStateToken] = useState("");
    function ErroRepcatcha(token) {
        if (token) {
            dispatch(GetTokenRep(token));
        } else {
            dispatch(GetTokenRep(""));
        }
    }
    const handleChange2FA = (e) => {
        dispatch(GetValue2fa(e.target.value));
    };

    return (
        <div >

            <ReCAPTCHA
                ref={reRef}
                sitekey="6Le0C4QiAAAAAIVttIdRz7YtfVcGBXwZiDFRKHAp"
                onChange={async (token) => {
                    ErroRepcatcha(token);
                }}
                onExpired={(e) => {
                    setStateToken("");
                }}
            />


        </div>
    );
}
