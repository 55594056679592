import styled from "@emotion/styled";
import { themes } from "common/styles/theme";

export const Wrapper = {
  Main: styled.div`
    padding: 100px 0;
  `,
  Line: styled.div`
    border-radius: 30px;
    height: 5px;
    width: 80%;
    margin: auto;
    background: linear-gradient(
      115.36deg,
      rgba(244, 0, 116, 0.4) 33.92%,
      rgba(37, 19, 81, 0.4) 99.86%
    );
  `,
  Text: styled.div`
    text-align: center;
    font-size: 70px;
    font-weight: bold;
    color: white;
    text-shadow: 2px 2px #ff0000;
    @media (max-width: 769px) {
      font-size: 50px;
    }
    @media (max-width: 540px) {
      font-size: 35px;
    }
  `,
  Date: styled.div`
    color: #ffffffad;
    text-align: center;
    margin-top: -25px;
    font-family: "Roboto";
    font-weight: 500;
    @media (max-width: 1000px) {
      margin-top: 0px;
    }
  `,
  Button: styled.button`
    display: flex;
    margin: 30px auto;
    padding: 10px 50px;
    border-radius: 20px;
    color: white;
    font-weight: bold;
    background: linear-gradient(180deg, #e80274 0%, #7d0d6a 100%);
    font-family: "IBM Plex Sans";
    box-shadow: -1px -1px 15px 7px rgb(255 244 244 / 14%);
  `,
};
export const CountDown = {
  main: styled.div`
    box-shadow: -1px -1px 15px 7px rgb(255 244 244 / 14%);
    background: rgba(255, 255, 255, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 18px;
    margin-top: 20px;
    display: flex;
    width: fit-content;
    margin: auto;
  `,
  item: styled.div`
    ${themes.mixin.flexCenter()};
    flex-direction: column;
    padding: 10px;

    > h1 {
      margin: 0;
      color: #ffdee3;
      font-size: 48px;
      font-family: fantasy;
    }
    > p {
      color: #ffdee3;
      margin: 0;
    }
    > span {
      color: #ffdee3;
      font-size: 48px;
      margin-top: -30px;
    }
    @media (max-width: 500px) {
      padding: 8px;
      > h1 {
        margin: 0;
        color: #ffdee3;
        font-size: 24px;
      }
      > p {
        font-size: 12px;
        color: #ffdee3;
        margin: 0;
      }
      > span {
        color: #ffdee3;
        font-size: 24px;
        margin-top: -24px;
      }
    }
  `,
};
export const Context = styled.div`
  color: white;
  text-align: center;
  padding: 30px 0;
  > span {
    font-weight: 700;
    font-size: 18px;
  }

  @media (max-width: 991px) {
    text-align: center;
  }
`;
export const LinkMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
