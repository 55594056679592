import BannerCountDown from "./BannerCountDown";
import Faq from "./Faq";
import Newspapers from "./Newspapers";
import NftItem from "./NftItem";
import Trusted from "./Trusted";
import Allocation from "./allocation";
import Banner from "./banner";
import Footer from "./footer";
import Header from "./header";
import Roadmap from "./roadmap";
import bg from "assets/svg/bg-Trusted.svg";
import React from "react";

export default function HomeMain() {
  return (
    <div
      div
      style={{
        background: "#08021c",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Header />
      <Banner />
      <Newspapers />
      <Trusted />
      {/* <NftItem /> */}
      <Allocation />
      <Roadmap />
      <BannerCountDown />
      <Faq />
      <Footer />

      <img
        src={bg}
        alt=""
        style={{
          position: "absolute",
          top: "50%",
          left: "-25%",
          width: "60%",
          overflow: "hidden",
          zIndex: "1",
        }}
      />
    </div>
  );
}
