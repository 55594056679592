const MOBILE = "600px";
const TABLET = "900px";
const TABLET_PRO = "1208px";
const LAPTOP = "1440px";
export const flexCenter = () => {
  return `display: flex; 
        justify-content:center;
        align-items:center`;
};
export const flexStart = () => {
  return `display: flex; 
        justify-content:center;
        align-items:start`;
};
export const flexRowCenter = () => {
  return `display: flex; 
          justify-content:center;`;
};
export const flexColCenter = () => {
  return `display: flex; 
  align-items:center`;
};
export const flexDirection = () => {
  return `display: flex; 
  flex-direction:column;
  align-items:center`;
}
export const flexDirectionNotCenter = () => {
  return `display: flex; 
  flex-direction:column;
  `
}
export const flex = () => {
  return `display: flex;
  `;
}

export const grid = () => {
  return `display: grid;
  grid-template-columns: repeat(3,1fr);
  `;
}
export const positionCenter = () => {
  return `position: absolute;
  left: 50 %;
  top: 50 %;
  transform: translate(-50 %, -50 %);
  `;
};
export const deviceMobile = (content) => {
  return `@media screen and(max - width: ${MOBILE - 1}) {
    ${content};
    }`;
};
export const deviceTablet = (content) => {
  return `@media screen and(max - width: ${TABLET - 1}) {
      ${content};
} `;
};
export const deviceTabletPro = (content) => {
  return `@media screen and(min - width: ${TABLET} and max - width: ${TABLET_PRO - 1
    }) {
      ${content};
} `;
};
export const deviceLaptop = (content) => {
  return `@media screen and(min - width: ${TABLET_PRO} and max - width: ${LAPTOP}) {
      ${content};
} `;
};
