import { Historys, Wrapper } from "./styles";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Pagination, Box, CircularProgress } from "@mui/material";
import { fixedNumber, formatMoney } from "components/FormatNumber";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleGetHistoryEarning,
  handleGetHistoryProfit,
} from "redux/slices/history";
import {
  handleGetHistoryBuy,
  handleGetHistoryType,
} from "redux/slices/staking";

const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      padding: "10px",
    },
    root: {
      "& .Mui-selected": {
        background: "#6828db !important",
        color: "white",
      },
      "& ul > li > button": {
        backgroundColor: "white",
        color: "black",
      },
      "&:hover ul > li > button": {
        backgroundColor: "white",
        color: "black",
      },
    },
  })
);

const Earning = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const classes = useStyles();
  const { loadding, historyEarning } = useSelector((state) => state.history);

  useEffect(() => {
    dispatch(handleGetHistoryEarning(page));
  }, [page]);

  return (
    <Wrapper>
      <div className="container">
        <Historys.main>
          <div>
            <h2
              style={{
                color: "white",
                margin: 0,
                padding: "10px 20px",
                fontSize: "32px",
              }}
            >
              History Earning
            </h2>
          </div>
          <Historys.context>
            <Historys.itemTitle>
              <div style={{ flex: 1 }}>#</div>
              <div style={{ flex: 2 }}>Location</div>
              <div style={{ flex: 2 }}>Time </div>
              <div style={{ flex: 2 }}>Amount</div>
              <div style={{ flex: 2 }}>Time</div>
            </Historys.itemTitle>
            <div style={{ minHeight: "250px" }}>
              {loadding ? (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <CircularProgress />{" "}
                </div>
              ) : historyEarning?.total > 0 ? (
                historyEarning?.data?.map((e, i) => (
                  <Historys.item key={i}>
                    <div style={{ flex: 1 }}>{i + 1 + (page - 1) * 10}</div>
                    <div style={{ flex: 2 }}>{e?.name_location} </div>
                    <div style={{ flex: 2 }}>
                      {e?.time} {e?.type_time}
                    </div>
                    <span style={{ flex: 2 }}>
                      {formatMoney(String(fixedNumber(e?.price, 2)))}{" "}
                      <strong
                        style={{
                          color: "#E10374",
                          fontWeight: "bold",
                        }}
                      >
                        {e?.init}{" "}
                      </strong>
                    </span>
                    <div style={{ flex: 2 }}>
                      {e?.date_buy?.substring(0, 10)}{" "}
                      {e?.date_buy?.substring(11, 19)}{" "}
                    </div>
                  </Historys.item>
                ))
              ) : (
                <div style={{ textAlign: "center", padding: "20px" }}>
                  Empty data...
                </div>
              )}
            </div>

            <Box className={classes.box}>
              <Pagination
                count={
                  historyEarning?.total
                    ? Math.ceil(historyEarning?.total / 10)
                    : 0
                }
                size="large"
                variant="outlined"
                onChange={(e, p) => setPage(p)}
                className={classes.root}
              />
            </Box>
          </Historys.context>
        </Historys.main>
      </div>
    </Wrapper>
  );
};
export default Earning;
