import { themes } from "common/styles/theme";
import styled from "styled-components";

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
`;

export const Wrapper = {
  section: styled.div`
    overflow: hidden;
    height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
  `,
  main: styled.div`
    background: rgba(8, 2, 28, 0.8);
    box-shadow: 0px 10px 28px rgb(0 0 0 / 12%);
    border-radius: 20px;
    width: 100%;
    max-width: 600px;
    z-index: 11;
    margin: 90px auto;
    position: relative;
  `,
  title: styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    text-align: center;

    border-radius: 20px 20px 0px 0px;
    color: ${themes.colors.white};
    > h2 {
      text-align: center;
      width: 100%;
      color: white;
      font-weight: bold;
      font-size: 28px;
    }
  `,
  context: styled.div`
        padding: 20px  20px 50px 20px;
        display: flex;
        flex-direction: column;
        gap: 30px;
     
        >button{
            height:45px;
            background: linear-gradient(to right, #7C0D6A, #EA0274 );
            border-radius: 5px;
            text-transform: uppercase;
            color:${themes.colors.white};
            font-weight:500;
      
    `,
  input: styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: ${themes.colors.white};
    > input {
      width: 100%;
      height: 40px;
      border-radius: 7px;
      color: ${themes.colors.black};
      padding-left: 15px;
    }
  `,
  link: styled.div`
    ${themes.mixin.flexDirectionNotCenter()};
    gap: 5px;
    color: ${themes.colors.white};
    @media (max-width: 500px) {
      font-size: 11px;
    }
  `,
};
