import { Wrapper } from "./styles";
import benzinga from "assets/img/newspapers/benzinga.png";
import digitaljournal from "assets/img/newspapers/digitaljournal.png";
import marketwatch from "assets/img/newspapers/marketwatch.png";
import morningstart from "assets/img/newspapers/morningstart.png";
import street from "assets/img/newspapers/street.png";
import yahoo from "assets/img/newspapers/yahoo.png";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";

const Newspapers = () => {
  return (
    <>
      <div className="container">
        <Wrapper.Main data-aos="fade-up">
          <Marquee speed={50} gradientColor={[8, 2, 28]}>
            <Wrapper.Logo
              href="https://www.marketwatch.com/press-release/the-first-ever-metaverse-based-marketing-platform-to-earn-now-is-finally-here-2022-11-01?mod=search_headline"
              target="_blank"
            >
              <img src={marketwatch} alt="" />
            </Wrapper.Logo>
            <Wrapper.Logo
              href="https://finance.yahoo.com/news/first-ever-metaverse-based-marketing-174500997.html?guccounter=1"
              target="_blank"
            >
              <img src={yahoo} alt="" />
            </Wrapper.Logo>
            <Wrapper.Logo
              href={
                "https://www.digitaljournal.com/pr/the-first-ever-metaverse-based-marketing-platform-to-earn-now-is-finally-here"
              }
              target={"_blank"}
            >
              <img src={digitaljournal} alt="" />
            </Wrapper.Logo>
            <Link to={"/"}>
              <img src={benzinga} alt="" />
            </Link>
            <Link to={"/"}>
              <img src={morningstart} alt="" />
            </Link>
            <Link to={"/"}>
              <img src={street} alt="" />
            </Link>
          </Marquee>
        </Wrapper.Main>
      </div>
    </>
  );
};
export default Newspapers;
