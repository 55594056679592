import loadingG from "assets/img/loading-26.gif";
import React from "react";

function LoaddingGif(props) {
  return (
    <div
      style={{
        background: "none",
        minHeight: "100vh",
        minWidth: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img style={{ maxWidth: "250px" }} src={loadingG} alt="" />
    </div>
  );
}

export default LoaddingGif;
