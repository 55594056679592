import NftItem1 from "assets/svg/NftItem.svg";
import NftItem2 from "assets/svg/NftItem2.svg";
import NftItem3 from "assets/svg/NftItem3.svg";
import NftItem4 from "assets/svg/NftItem4.svg";
import NftItem5 from "assets/svg/NftItem5.svg";





export const listFeatures = [{
        title: "Classic: $500",
        img: NftItem1,
        des: "Amplify your position with high leverage, and capture the market’s constant stream of profit opportunity.",
    },
    {
        title: "Silver: $1,000",
        img: NftItem2,
        des: "300+ featured US and Hong Kong stocks, including popular international securities. Our broad range of products will elevate your effortless capital appreciation.",

    },
    {
        title: "Gold: $3,000",
        img: NftItem3,
        des: "Quotes of up to level 8 market depth, real-time display of market trading volume, liquidity insight data, and visualize market fluctuations to grasp opportunities.",

    },
    {
        title: "Platinum $7,000",
        img: NftItem4,
        des: "Quotes of up to level 8 market depth, real-time display of market trading volume, liquidity insight data, and visualize market fluctuations to grasp opportunities.",

    },
    {
        title: "VIP NOW $10,000",
        img: NftItem5,
        des: "Quotes of up to level 8 market depth, real-time display of market trading volume, liquidity insight data, and visualize market fluctuations to grasp opportunities.",

    },

];