import BannerCountDown from "./BannerCountDown";
import Filter from "./Filter";
import NFTLocations from "./NFTLocations";
import { Wrapper } from "./styles";
import Footer from "pages/HomeMain/footer";
import Header from "pages/HomeMain/header";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { handleGetPriceNow } from "redux/slices/wallet";

const Marketplace = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(handleGetPriceNow());
  }, []);
  return (
    <div div style={{ position: "relative", overflow: "hidden" }}>
      <Header />
      <div className="container">
        <Wrapper.Main data-aos="fade-up">
          <Wrapper.Line></Wrapper.Line>
          <Wrapper.Text>MARKETPLACE</Wrapper.Text>

          <Wrapper.Line></Wrapper.Line>
        </Wrapper.Main>
        <Filter />
        <NFTLocations />
      </div>
      <BannerCountDown />

      <Footer />
    </div>
  );
};

export default Marketplace;
