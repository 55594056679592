import { Wrapper } from "../styles";
import ModalDeleteAccount from "./ModalDeleteAccount";
import { Content } from "./styles";
import {
  Button,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import bg from "assets/img/background.png";
import bgs from "assets/img/background/bg-banner.png";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleCleanup } from "redux/slices/airdrop";
import { GetUser } from "utits/auth";

function DataDeletion() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.airdrop);
  const [dataCleanup, setDataCleanup] = useState({
    nftlocation: 0,
    nftmachine: 0,
    nftcard: 0,
    userdata: 0,
    confirmcode: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDataCleanup((prevDataCleanup) => ({
      ...prevDataCleanup,
      [name]: value,
    }));
  };

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    setDataCleanup((prevDataCleanup) => ({
      ...prevDataCleanup,
      [name]: checked ? 1 : 0,
    }));
  };

  const onSubmit = async (data) => {
    try {
      dispatch(
        handleCleanup({
          ...dataCleanup,
          email: GetUser()?.email ? GetUser()?.email : dataCleanup.email,
          username: GetUser()?.username
            ? GetUser()?.username
            : dataCleanup.username,
          userid: GetUser()?.id || null,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Wrapper>
      <div style={{ width: "100%" }}>
        <img src={bgs} alt="" style={{ width: "100%" }} />
      </div>
      <div className="container" style={{ zIndex: 2 }}>
        <Content.main>
          <Content.container>
            <Content.image>
              <img src={bg} alt="" />
            </Content.image>
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ px: 2 }}
            >
              <Typography
                color="secondary"
                sx={{
                  p: { xs: 1 },
                  fontWeight: "500",
                  fontSize: {
                    xs: "23px",
                    sm: "26px",
                    md: "30px",
                  },
                }}
              >
                Account Cleanup
              </Typography>
              <Typography
                sx={{
                  color: "#ffffff",
                  fontWeight: "400",
                  fontSize: {
                    xs: "16px",
                    sm: "16px",
                    md: "18px",
                  },
                }}
              >
                What do you want to delete?
              </Typography>
              <Stack
                direction="column"
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <>
                  <FormControlLabel
                    sx={{
                      color: "#ffffff",
                      fontWeight: "400",
                      fontSize: {
                        xs: "16px !important",
                        sm: "16px",
                        md: "18px",
                      },
                    }}
                    control={<Switch color="secondary" />}
                    label="NFT Real Estate"
                    onChange={handleSwitchChange}
                    checked={dataCleanup.nftlocation === 1}
                    name="nftlocation"
                  />
                  <FormControlLabel
                    sx={{
                      color: "#ffffff",
                      fontWeight: "400",
                      fontSize: {
                        xs: "16px !important",
                        sm: "16px",
                        md: "18px",
                      },
                    }}
                    control={<Switch color="secondary" />}
                    label="NFT Virtual Machine"
                    onChange={handleSwitchChange}
                    checked={dataCleanup.nftmachine === 1}
                    name="nftmachine"
                  />
                  <FormControlLabel
                    sx={{
                      color: "#ffffff",
                      fontWeight: "400",
                      fontSize: {
                        xs: "16px !important",
                        sm: "16px",
                        md: "18px",
                      },
                    }}
                    control={<Switch color="secondary" />}
                    label="NFT Card"
                    onChange={handleSwitchChange}
                    checked={dataCleanup.nftcard === 1}
                    name="nftcard"
                  />
                  <FormControlLabel
                    sx={{
                      color: "#ffffff",
                      fontWeight: "400",
                      fontSize: {
                        xs: "16px !important",
                        sm: "16px",
                        md: "18px",
                      },
                      pb: 2,
                    }}
                    control={<Switch color="secondary" />}
                    label="User data (Device, Current location, KYC information)"
                    onChange={handleSwitchChange}
                    checked={dataCleanup.userdata === 1}
                    name="userdata"
                  />
                </>
                {!GetUser()?.username && (
                  <>
                    <Content.input>
                      <input
                        type="text"
                        name="username"
                        placeholder="Username"
                        value={dataCleanup.username}
                        onChange={handleChange}
                      />
                    </Content.input>
                    <Content.input>
                      <input
                        type="text"
                        name="email"
                        placeholder="Email"
                        value={dataCleanup.email}
                        onChange={handleChange}
                      />
                    </Content.input>
                  </>
                )}

                <Content.input>
                  <input
                    type="text"
                    name="confirmcode"
                    placeholder="Confirm Code"
                    value={dataCleanup.confirmcode}
                    onChange={handleChange}
                  />
                </Content.input>
              </Stack>
              <Button
                variant="outlined"
                color="error"
                onClick={() => onSubmit()}
                sx={{ mt: 2, width: "154px" }}
              >
                Delete Data
              </Button>
              <Typography
                color="secondary"
                sx={{
                  p: { xs: 1 },
                  mt: 2,
                  fontWeight: "500",
                  fontSize: {
                    xs: "23px",
                    sm: "26px",
                    md: "30px",
                  },
                }}
              >
                Account Deletion
              </Typography>
              <Button
                disabled={!GetUser()?.username}
                variant="outlined"
                color="error"
                onClick={handleClickOpen}
              >
                Delete Account
              </Button>
            </Stack>
          </Content.container>
          <ModalDeleteAccount setOpen={setOpen} open={open} />
        </Content.main>
      </div>
    </Wrapper>
  );
}

export default DataDeletion;
