import {
  CheckTimeMining,
  CheckTimeMiningCard,
  Finish,
  FinishMining,
  handleCheckTimeMining,
} from "../mining";
import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import CreateApi from "api/CreateApi";
import {
  ErrorMessage,
  MessageToastError,
  SuccessMessage,
} from "components/Message";

const initialState = {
  groupMining: false,
  childrenDrawer: false,
  listTimeCard: [],
  checkMining: [],
  checkTimeMiningCard: false,
  FinishMiningCard: false,

  finishCard: [],
  rewardEarningCard: [],
  loadding: false,
};

export const handleGetListTimeCard = () => {
  return async (dispatch) => {
    try {
      let res = await CreateApi.CreateApiSSS(
        "public/minning/timming-card?coin=NOW",
        true
      );
      dispatch(ListTimeCard(res?.data?.data));
    } catch (error) {}
  };
};
export const handleGetRewardEarningCard = () => {
  return async (dispatch) => {
    try {
      let res = await CreateApi.CreateApiSSS(
        "public/minning/config-minning-card",
        true
      );
      dispatch(RewardEarningCard(res?.data?.data));
    } catch (error) {}
  };
};

export const handleFinishCard = (
  locationid,
  // type_range,
  setCheckedStateCard
) => {
  const data = {
    locationid: locationid,
    // type_range: type_range,
  };
  return async (dispatch) => {
    const key = "updatable";
    try {
      let res = await CreateApi.CreateApi1(
        `public/minning/minning-card`,
        data,
        true
      );
      if (res?.data?.status === true) {
        message.loading({ content: "Loading...", key });
        setTimeout(() => {
          message.success({
            content: "Successful income!!",
            key,
            duration: 10,
          });
        }, 1000);
        dispatch(CheckTimeMiningCard(false));

        dispatch(Finish(res?.data?.data));
        setCheckedStateCard(false);
        dispatch(FinishMining(true));
      } else {
        setCheckedStateCard(true);
        MessageToastError(res?.data?.message);
      }
    } catch (error) {}
  };
};

export const handleCheckInCard = (data) => {
  return async (dispatch) => {
    dispatch(Loadding(true));
    try {
      let res = await CreateApi.CreateApi1(
        `public/minning/check-in`,
        data,
        true
      );
      if (res?.data?.status === true) {
        dispatch(CheckInCard(res?.data?.data));
        dispatch(Loadding(false));
      } else {
        dispatch(Loadding(false));
        MessageToastError(res?.data?.message);
      }
    } catch (error) {
      dispatch(Loadding(false));
    }
  };
};

export const handleCheckOutCard = (data) => {
  return async (dispatch) => {
    dispatch(Loadding(true));
    try {
      let res = await CreateApi.CreateApi1(
        `public/minning/check-in`,
        data,
        true
      );
      if (res?.data?.status === true) {
        dispatch(CheckOutCard(res?.data?.data));
        dispatch(Loadding(false));
      } else {
        dispatch(Loadding(false));
        MessageToastError(res?.data?.message);
      }
    } catch (error) {
      dispatch(Loadding(false));
    }
  };
};

export const handleCheckTimeMiningCard = () => {
  return async (dispatch) => {
    try {
      let res = await CreateApi.CreateApiSSS(
        `public/minning/time-minning-card`,
        true
      );
      if (res?.data?.status === false) dispatch(CheckTimeMining(false));
      else {
        dispatch(CheckTimeMining(res?.data?.data));
      }
    } catch (error) {}
  };
};

export const handleCheckMining = (
  myLatiude,
  myLongitude,
  locaLatiude,
  locaLongitude
) => {
  return async (dispatch) => {
    try {
      let res = await CreateApi.CreateApiSSS(
        `public/minning/check-minning?myLatiude=${myLatiude}&myLongitude=${myLongitude}&locaLatiude=${locaLatiude}&locaLongitude=${locaLongitude}`,
        true
      );
      dispatch(CheckMining(res?.data));
    } catch (error) {}
  };
};

const miningSlice = createSlice({
  name: "earningcard",
  initialState,
  reducers: {
    GroupMining: (state, action) => {
      return {
        ...state,
        groupMining: action.payload,
      };
    },
    ListTimeCard: (state, action) => {
      return {
        ...state,
        listTimeCard: action.payload,
      };
    },
    CheckMining: (state, action) => {
      return {
        ...state,
        checkMining: action.payload,
      };
    },
    FinishMiningCard: (state, action) => {
      return {
        ...state,
        finishMiningCard: action.payload,
      };
    },
    ChildrenDrawer: (state, action) => {
      return {
        ...state,
        childrenDrawer: action.payload,
      };
    },
    // CheckTimeMiningCard: (state, action) => {
    //   return {
    //     ...state,
    //     checkTimeMiningCard: action.payload,
    //   };
    // },

    FinishCard: (state, action) => {
      return {
        ...state,
        finishCard: action.payload,
      };
    },

    RewardEarningCard: (state, action) => {
      return {
        ...state,
        rewardEarningCard: action.payload,
      };
    },
    Loadding: (state, action) => {
      return {
        ...state,
        loadding: action.payload,
      };
    },
    CheckInCard: (state, action) => {
      return {
        ...state,
        checkInCard: action.payload,
      };
    },
    CheckOutCard: (state, action) => {
      return {
        ...state,
        checkOutCard: action.payload,
      };
    },
  },
});

const { reducer, actions } = miningSlice;
export const {
  GroupMining,
  ChildrenDrawer,
  ListTimeCard,
  CheckMining,

  FinishMiningCard,
  StartMiningCard,
  FinishCard,
  RewardEarningCard,
  Loadding,
  CheckInCard,
  CheckOutCard,
} = actions;
export default reducer;
