import { Container } from "react-bootstrap";
import styled from "styled-components";

export const ContainerStyled = styled(Container)`
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 20px;
  z-index: 2;
  > h2 {
    color: white;
    font-size: 40px;
  }
  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    min-height: 500px;
  }
`;

export const Wrapper = {
  main: styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;

    > div {
      height: ${(props) => (props.Mheight ? "auto" : "fit-content")};
      color: white;
      padding: 5px;
      margin: 0 !important;
    }
    @media (max-width: 991px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 10px;
    }
  `,
};
