import FormCreateLocation from "./Form";
import Mana from "./Mana";
import Timeline from "./Timeline";
import bg from "assets/svg/bg-Trusted.svg";
import NotVerifyAccount from "components/NotVerifyAccount";
import Footer from "pages/HomeMain/footer";
import Header from "pages/HomeMain/header";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { handleGetBlockNow } from "redux/slices/ICO";
import { handleGetPriceNow } from "redux/slices/wallet";
import { GetUser } from "utits/auth";

const CreateLocation = () => {
  const user = GetUser();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(handleGetBlockNow());
    dispatch(handleGetPriceNow());
  }, []);
  if (!user) return <Navigate to="/auth/login" />;
  return (
    <>
      <Header />
      <div
        style={{
          background: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      >
        <div className="container">
          {" "}
          <br />
          <br />
          <br />
          <br />
          <br />
          <Mana />
          <Timeline />
          {user?.status === "ACTIVE" && <FormCreateLocation />}
          {user?.status === "VERIFY" && <NotVerifyAccount />}
        </div>
      </div>

      <Footer />
    </>
  );
};
export default CreateLocation;
