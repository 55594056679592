import { ContainerStyled, Section, Wrapper } from "./styled";
import bgmain from "assets/svg/bg-roadmap.svg";
import bg from "assets/svg/roadmap.svg";
import React from "react";

export default function Roadmap() {
  return (
    <Section id="ROADMAP">
      <ContainerStyled>
        <Wrapper.main>
          <h1 data-aos="fade-up" data-aos-offset="30">
            Roadmap
          </h1>

          <div data-aos="zoom-in" data-aos-offset="30">
            <img src={bg} alt="" />
          </div>
        </Wrapper.main>
        <img src={bgmain} alt="" />
      </ContainerStyled>
    </Section>
  );
}
