import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { ContainerStyled, Wrapper } from './styles';
import { listFqa } from './data';




export default function Faq() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
 
 
  return (
    <ContainerStyled>
        <h2 data-aos="fade-up" data-aos-offset="30">FAQ</h2>
        <Wrapper.main data-aos="fade-up" data-aos-offset="30">

        {listFqa?.map((e,i)=> (
            <Accordion expanded={expanded === i} onChange={handleChange(i)} style={{borderRadius:'32px',background:" linear-gradient(115.36deg, rgba(80, 45, 159, 0.4) 33.92%, rgba(8, 2, 28, 0) 99.86%)"}} Mheight={expanded === i} >
            <AccordionSummary
              expandIcon={expanded === i ? <ArrowDropDownIcon style={{color:'white'}} /> :<ArrowRightIcon style={{color:'white'}} />}
              aria-controls={`panel${i}bh-content`}
              id={`panel${i}bh-header`}
            >
              <Typography sx={{ width: '50%', flexShrink: 0 }}>
                {e.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails  style={{height:`${expanded === i} ? height:"auto !important" : height:'0 !important'`}}>
          <Typography>
            {e?.des}
          </Typography>
        </AccordionDetails>
          
          </Accordion>

       ))}


       </Wrapper.main>
     
     
    </ContainerStyled>
  );
}
