export const PATH = {
  DASHBOARD: {
    SWAP: "/swap",
    FARM: "/farm",
    GAMEFI: "/game-fi",
    REFERRAL: "/referral",
    FARMING: "/farming",
    BUY_NFT: "/buy-nft",
    MY_NFT: "/my-nft",
    HISTORY: "/history",
    CLAIM: "/claim",
    ACCOUNT: "/account",
    KYC: "/kyc",
    PROFILE: "/profile",
    WALLET: "/wallet",
    BUYICO: "/buyico",
    MINING: "/mining",
    EARNING: "/earning",
    PROMOTION: "/promotion",
    SUPPORT: "/auth/contact",
    AIRDROP: "airdrop",
    DATA_DELECTION: "/data-delection",
  },
  AUTH: {
    SELF: "auth",
    LOGIN: "login",
    REGISTER_SLUG: "register/:slug",
    REGISTER: "register",
    // REGISTERID: "register/:slug/:slug2",
    VERIFY: "verify",
    AWAIT: "await",
    FORGOT: "forgot-password",
    RESEND_MAIL: "resend-mail",
    VERIFYFORGOT: "generate-new-password",
    TERMS_OF_SERVICE: "terms-of-service",
    PRIVACY_POLICE: "privacy-policy",
    POLICY: "policy",
    CONTACT: "contact",
  },
  MAPS: {
    MAPS: "maps",
    MAPS_DETAIL: "maps/:slug",
    APP_MINT_NFT: "app-mint-nft",
  },
  WALLETS: {
    DEPOSIT: "deposit",
    WITHDRAW: "withdraw",
  },
};
