import { themes } from "common/styles/theme";
import styled from "styled-components";

export const Content = {
  main: styled.div`
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 18px;
    ${themes.mixin.flexRowCenter()};
    gap: 20px;
    align-items: center;
    flex-direction: column;
    @media (min-width: 600px) {
      padding: 30px 15px;
    }s
    padding: 20px 5px;
  `,
  image: styled.div`
    > img {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
      z-index: -1;
    }
  `,
  container: styled.div`
    max-width: 600px;
    width: 100%;
    border-radius: 15px;
    z-index: 2;
  `,
  title: styled.div`
    h2 {
      color: ${themes.colors.white};
      margin-bottom: 30px;
    }
  `,
  wrapper: styled.div`
    ${themes.mixin.flexDirectionNotCenter()};
    gap: 20px;
    span {
      color: ${themes.colors.white};
      > strong {
        color: ${themes.colors.sky};
      }
    }
    > div {
      > button {
        width: 277px;
        height: 53px;
        background: linear-gradient(180deg, #e80274 0%, #7d0d6a 100%);
        border: 1px solid rgba(255, 255, 255, 0.16);
        border-radius: 74px;
        text-transform: uppercase;
        color: ${themes.colors.white};
        font-weight: 500;
        font-size: 20px;
      }
    }
  `,
  input: styled.div`
    display: flex;
    min-width: 330px;
    padding: 5px 0;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    color: ${themes.colors.white};
    > input {
      width: 100%;
      border-radius: 7px;
      color: ${themes.colors.white};
      padding-left: 15px;
      cursor: pointer;
      background: none;
      height: 55px;
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 10px;
    }
  `,
  Qr: styled.div`
    ${themes.mixin.flexCenter()};
    > div {
      padding: 20px;
      background: ${themes.colors.white};
    }
  `,
};
