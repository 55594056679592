import styled from "styled-components";

export const Wrapper = {
  Main: styled.div`
    padding-top: 100px;
    @media (max-width: 800px) {
      padding-top: 10px;
    }
  `,
  Line: styled.div`
    border-radius: 30px;
    height: 5px;
    width: 70%;
    margin: auto;
    background: linear-gradient(
      115.36deg,
      rgba(244, 0, 116, 0.4) 33.92%,
      rgba(37, 19, 81, 0.4) 99.86%
    );
  `,
  Text: styled.div`
    text-align: center;
    font-size: 70px;
    font-weight: bold;
    color: white;
    text-shadow: 2px 2px #ff0000;
    @media (max-width: 769px) {
      font-size: 50px;
    }
    @media (max-width: 540px) {
      font-size: 35px;
    }
  `,
  Date: styled.div`
    color: #ffffffad;
    text-align: center;
    margin-top: -25px;
    font-family: "Roboto";
    font-weight: 500;
    @media (max-width: 1000px) {
      margin-top: 0px;
    }
  `,
  Button: styled.button`
    display: flex;
    margin: 30px auto;
    padding: 10px 50px;
    border-radius: 20px;
    color: white;
    font-weight: bold;
    background: linear-gradient(180deg, #e80274 0%, #7d0d6a 100%);
    font-family: "IBM Plex Sans";
    box-shadow: -1px -1px 15px 7px rgb(255 244 244 / 14%);
  `,
};

export const Form = {
  Main: styled.div``,
};
export const Content = {
  Left: styled.div`
    width: 100%;
    margin: auto;
    @media (max-width: 800px) {
      display: flex;
      > img {
        width: 50%;
        margin: auto;
      }
    }
  `,
  Input: styled.div`
    > input {
      padding: 10px 20px;
      width: 100%;
      border-radius: 20px;
    }
    > textarea {
      padding: 10px 20px;
      width: 100%;
      border-radius: 20px;
    }
    > label {
      color: #ffffffba;
      margin-left: 5px;
    }
  `,
  Right: styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    > button {
      background: linear-gradient(90deg, #780d69 0%, #ec0174 100%);
      box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
      border-radius: 50px;
      border: none;
      width: 118px;
      height: 35px;
      color: white;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      margin: auto;
      &:hover {
        transition: 0.5s;
        background: linear-gradient(90deg, #531049 0%, #9a0c51 100%);
      }
    }
  `,
  Main: styled.div`
    display: flex;
    gap: 50px;
    padding-top: 50px;
    @media (max-width: 800px) {
      flex-direction: column;
      padding-top: 20px;
    }
  `,
  Name: styled.div`
    display: flex;
    gap: 10px;
    > input {
      padding: 10px 20px;
      width: 100%;
      border-radius: 20px;
    }
  `,
};
