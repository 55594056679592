import { Links, ListProducts, Privacy } from "./data";
import {
  Address,
  ContainerStyled,
  Context,
  CopyR,
  LinkMain,
  Section,
  Warraper,
  WarraperAddress,
  WarraperLink,
} from "./footer.styled";
import bg from "assets/svg/bg-footer.svg";
import Image from "assets/svg/logoFooter.svg";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { HandleOpenDrawer } from "redux/slices/auth";
import { MenuDrawer } from "redux/slices/menu";

function Footer() {
  const dispatch = useDispatch();
  return (
    <Section>
      <ContainerStyled>
        <Warraper>
          <WarraperAddress>
            <div style={{ textAlign: "center" }}>
              <img style={{ maxWidth: "145px" }} src={Image} alt="" />
            </div>
            <Address>
              <LinkMain>
                <div style={{ textAlign: "center" }}>
                  <b>
                    <a
                      href="https://wyobiz.wyo.gov/Business/FilingSearch.aspx#&&xmAIsuOafhpZkP/dhlOOQw6GNEfwoJr5O3ou0ibuidcxExYjOx025aZLfBspA0vyYpwn0tbHsdu/8Jp4rUU9yH5LRKmD8uFHp4cjCwHB8wO8EskSg1M86rF3e0PXsJMq4qaM91jxkVJW918A+9TmohrRrz7yTS3h0t52HNbvxQEF4K8nSdFjLw0W6UVTr48ihLkwFw=="
                      target="_blank"
                    >
                      To Earn Now, LLC
                    </a>{" "}
                  </b>
                  <br />
                  <a
                    href="https://wyobiz.wyo.gov/Business/FilingSearch.aspx#&&xmAIsuOafhpZkP/dhlOOQw6GNEfwoJr5O3ou0ibuidcxExYjOx025aZLfBspA0vyYpwn0tbHsdu/8Jp4rUU9yH5LRKmD8uFHp4cjCwHB8wO8EskSg1M86rF3e0PXsJMq4qaM91jxkVJW918A+9TmohrRrz7yTS3h0t52HNbvxQEF4K8nSdFjLw0W6UVTr48ihLkwFw=="
                    target="_blank"
                  >
                    1309 Coffeen Ave. Ste 1200, Sheridan, WY 82801 USA
                  </a>
                </div>
              </LinkMain>
            </Address>
          </WarraperAddress>
          <WarraperLink>
            {/* <Context>
              <span>Products</span>
              <LinkMain>
                {ListProducts?.map((e, i) => (
                  <span key={i}>{e?.text}</span>
                ))}
              </LinkMain>
            </Context> */}
            <Context>
              <span>Legal</span>
              <LinkMain>
                <a href="https://toearnnow.com/Terms-of-Service.pdf">
                  Terms Of Service
                </a>
                <Link to="/data-delection">Account Cleanup</Link>
                <Link to="/data-delection">Account Deletion</Link>
                {Privacy?.map((e, i) => (
                  <Link to={e.link} key={i}>
                    {e?.text}
                  </Link>
                ))}
              </LinkMain>
            </Context>

            <Context>
              <span>Community</span>
              <LinkMain
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(4,1fr)",
                  gap: "10px",
                }}
              >
                {Links?.map((e, i) => (
                  <a href={e.link} target="_blank">
                    <img
                      key={i}
                      src={e?.image}
                      alt=""
                      style={{ width: "35px", height: "35px" }}
                    />
                  </a>
                ))}
              </LinkMain>
            </Context>
          </WarraperLink>
        </Warraper>
        <CopyR>Copyright © 2022. All Rights Reserved</CopyR>
      </ContainerStyled>
      <img src={bg} alt="" />
    </Section>
  );
}

export default Footer;
