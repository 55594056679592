import styled from "styled-components";

export const Main = {
  Content: styled.div`
    width: 100%;

    margin-top: 50px;
    display: flex;
    padding: 40px 80px;
    @media (max-width: 767px) {
      padding: 30px 20px;
      flex-direction: column;
    }
    @media (min-width: 768px) {
      height: 80vh;
    }
    gap: 50px;
  `,

  Left: styled.div`
    @media (max-width: 767px) {
      flex: 100%;
    }
    flex: 30%;
  `,
  Right: styled.div`
    @media (min-width: 767px) {
      flex: 100%;
    }
    flex: 70%;
  `,
  Title: styled.h1`
    @media (max-width: 768px) {
      margin-top: 50px;
      font-size: 2.5rem;
    }
    margin-top: 100px;
    text-align: center;
    font-size: 4.5rem;
    line-height: 1.25;
    font-weight: 700;
    background-image: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#fff),
      color-stop(60%, #ffa5cb),
      to(#978fff)
    );
    background-image: linear-gradient(90deg, #fff, #ffa5cb 60%, #978fff);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  `,

  Version: styled.h3`
    color: #02a878;
    padding-bottom: 30px;
    font-weight: 400;
  `,
  ContentLeft: styled.div`
    background: #f5f5f9;
    border-radius: 15px;
    .active {
      background-color: #daebf1;
      color: #02a878;
    }
  `,
  ContentRight: styled.div`
    // height: 500px;
    // overflow-y: scroll;
    color: white;
    font-size: 1.25rem;
    @media (max-width: 768px) {
      font-size: 1rem;
    }
    > ul > li {
      margin-left: 30px;
    }
  `,
  Item: styled.div`
    padding: 20px 30px;

    border-radius: 15px;
    cursor: pointer;
    font-weight: 500;
  `,
};
