import {
  ContainerStyled,
  RowStyled,
  Section,
  ColStyled,
  Download,
} from "./Banner.Styled";
import Adroin from "assets/svg/Adroin.svg";
import IOS from "assets/svg/IOS.svg";
import bg from "assets/svg/bg-Trusted.svg";
import Bg from "assets/svg/bg-countdown.svg";
import { useCountDown } from "hooks/useCountDown";
import { useNavigate } from "react-router-dom";
import { animated } from "react-spring";

function BannerCountDown() {
  const navigate = useNavigate();
  const [days, hours, minutes, seconds] = useCountDown(
    new Date("November 1, 2022 00:00:00")
  );

  return (
    <>
      <Section>
        <ContainerStyled>
          <RowStyled style={{ alignItems: "center" }}>
            <ColStyled md={12} lg={6} data-aos="fade-up" data-aos-offset="30">
              <Download.main>
                <Download.wrapper>
                  <h1 data-aos="zoom-in-up" data-aos-offset="30">
                    <strong> NOW </strong> Mobile <br />{" "}
                    <p style={{ fontSize: "16px" }}>In Development</p>
                  </h1>
                  <div data-aos="zoom-in-up" data-aos-offset="30">
                    {/* <p>Download NOW Wallet</p> */}
                    <div>
                      <img src={IOS} alt="" />
                      <img src={Adroin} alt="" />
                    </div>
                  </div>
                </Download.wrapper>
              </Download.main>
            </ColStyled>

            <ColStyled
              md={12}
              lg={6}
              data-aos="zoom-in-up"
              data-aos-offset="30"
            >
              <img src={Bg} alt="" />
            </ColStyled>
          </RowStyled>
        </ContainerStyled>
      </Section>
      <img
        src={bg}
        alt=""
        style={{
          position: "absolute",
          bottom: "0",
          width: "80%",
          right: "-45%",
          zIndex: "2",
        }}
      />
    </>
  );
}

export default BannerCountDown;
