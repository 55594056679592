import { Box } from "@mui/material";
import { themes } from "common/styles/theme";
import { theme } from "components/styles/Theme";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

export const Section = styled.section`
  background: url(${(props) => props.src});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 700px;
  width: 100vw;
  position: relative;
  @media (max-width: 1000px) {
    background-size: cover;
    padding: 50px;
    height: 700px;
    // min-height: 700px;
    background-position: 35% 0%;
  }
  @media (max-width: 600px) {
    padding: 0;
    width: 100% !important;
    height: 357px;
    margin-top: 71px;
  }
`;
export const ContainerStyled = styled(Container)`
  width: 100%;
  height: 100%;
  position: relative;
  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;
export const RowStyled = styled(Row)`
  height: 100%;
  width: 100%;
  padding: 50px 0;
  @media (max-width: 991px) {
    flex-direction: column;
    gap: 20px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 40px;
  }
`;

export const ColStyled = styled(Col)`
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  > div {
    ${themes.mixin.flexCenter()};
    gap: 8px;
    > h1 {
      margin: 0;
      color: white;
      font-size: 32px;
      @media (max-width: 500px) {
        font-size: 24px;
      }
    }
    > strong {
      font-size: 32px;
      color: red;
      @media (max-width: 500px) {
        font-size: 24px;
      }
    }
  }
  @media (max-width: 991px) {
    justify-content: end;
    align-items: center;
  }
  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;
export const ColStyledRight = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Image = styled.img``;
export const Title = styled.div`
  > div {
    > h1 {
      color: white;
      font-family: "IBM Plex Sans";
      font-weight: 700;
      text-align: center;
      font-size: 48px;
      margin: 0;
      @media (max-width: 1000px) {
        font-size: 40px;
      }
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
  }
`;

export const CountDown = {
  main: styled.div`
    background: rgba(255, 255, 255, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 18px;
    margin-top: 20px;
  `,
  item: styled.div`
    ${themes.mixin.flexCenter()};
    flex-direction: column;
    padding: 10px;

    > h1 {
      margin: 0;
      color: #ffdee3;
      font-size: 48px;
    }
    > p {
      color: #ffdee3;
      margin: 0;
    }
    > span {
      color: #ffdee3;
      font-size: 48px;
      margin-top: -30px;
    }
    @media (max-width: 500px) {
      padding: 8px;
      > h1 {
        margin: 0;
        color: #ffdee3;
        font-size: 24px;
      }
      > p {
        font-size: 12px;
        color: #ffdee3;
        margin: 0;
      }
      > span {
        color: #ffdee3;
        font-size: 24px;
        margin-top: -24px;
      }
    }
  `,
};

export const StyledButton = styled.div`
  margin-top: 20px;
  > button {
    max-width: 194px;
    min-width: 130px;
    width: 100%;
    padding: 10px;
    border-radius: 74px;
    background: linear-gradient(90deg, #780d69 0%, #ec0174 100%);
    color: white;
    font-weight: bold;
    font-size: 16px;
  }
`;
