import styled from "styled-components";

export const Wrapper = {
  Main: styled.div`
    margin: auto;
    position: relative;
    z-index: 2;
    .css-1s2u09g-control {
      border-radius: 30px;
      height: 44px;
    }
    .rti--container {
      border-radius: 30px;
      min-height: 44px;
    }
    .css-1pahdxg-control {
      border-radius: 30px;
      height: 44px;
    }
  `,
  Title: styled.div`
    color: white;
    width: 100%;

    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 40px;
    font-weight: 700;
    text-shadow: 2px 2px #b11414d1;
  `,
  Context: styled.div`
    @media (max-width: 767px) {
      padding: 20px;
    }
    display: flex;
    padding: 20px 50px;
    flex-direction: column;
    gap: 10px;
    > input {
      width: 100%;
      padding: 5px 10px;
      border-radius: 30px;
      height: 37px;
    }
    > textarea {
      padding: 5px 10px;
      border-radius: 20px;
    }
  `,
  Submit: styled.button`
    font-weight: 500;
    color: white;
    font-size: large;
    background: linear-gradient(90deg, #780d69 0%, #ec0174 100%);
    padding: 5px 50px;
    width: fit-content;
    border-radius: 30px;
    margin: auto;
    box-shadow: -1px -1px 15px 7px rgb(255 244 244 / 14%);
    &:hover {
      transition: 0.7s;
      background: #6a15b9;
    }
  `,
  Des: styled.p`
    color: #b7afaf;
    text-align: center;
    padding: 15px 0;
  `,
};

export const ListImage = {
  Main: styled.div`
    height: 107px;
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
    overflow-x: scroll;
  `,
  Item: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    height: 100%;
    min-width: 90px;
    max-width: 90px;
    background: #6d6a7161;
    border-radius: 5px;

    > input {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      cursor: pointer;
      opacity: 0;
    }
    > img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    > svg {
      position: absolute;
      top: 0;
      right: 0;
      background-color: #ffebcd7d;
      border-radius: 50%;
      cursor: pointer;
    }
  `,
};
export const Address = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
  > input {
    width: 100%;
    padding: 5px 10px;
    border-radius: 30px;

    height: 44px;
  }
  > textarea {
    padding: 5px 10px;
    border-radius: 20px;
  }
`;

export const OpenHours = {
  Container: styled.div`
    max-height: 150px;
    overflow-y: scroll;
    margin-bottom: 10px;
  `,
  Main: styled.div`
    width: 100%;
    display: flex;
    gap: 30px;
    padding: 0px 20px;
    align-items: center;
    margin-bottom: 5px;
  `,
  Time: styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    color: white;
    width: 100%;
    > input {
      width: 100%;
      padding: 3px 10px;

      border-radius: 5px;
      color: black;
    }
  `,
  Text: styled.div`
    font-size: 14px;
  `,
  DayOfWeek: styled.div`
    color: white;
    font-weight: bold;
    min-width: 30px;
    text-transform: capitalize;
  `,
};

export const Option = {
  Main: styled.div`
    display: flex;
    color: white;
    width: 100%;
    align-items: center;
    gap: 30px;
  `,
  Radio: styled.div`
    display: flex;
    gap: 30px;
  `,
  Text: styled.div`
    font-weight: bold;
  `,
};

export const Price = styled.div`
  color: white;
  display: flex;
  justify-content: space-around;
  background: linear-gradient(
    115.36deg,
    rgba(80, 45, 159, 0.4) 33.92%,
    rgba(143, 121, 217, 0.4) 99.86%
  );
  padding: 10px 20px;
  border-radius: 30px;
  > p > span {
    color: #eb0274;
    font-weight: bold;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const PhoneInput = {
  Wrapper: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 2px;
  `,
  Code: styled.div`
    background-color: white;
    height: 44px;
    width: 15%;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Input: styled.input`
    width: 100%;
    padding: 5px 10px;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    height: 44px;
  `,
};
