import Trusted2 from "assets/svg/Trusted2.svg";
import Trusted3 from "assets/svg/Trusted3.svg";
import Trusted from "assets/svg/Trusted.svg";

export const listFeatures = [
  {
    img: Trusted,
    link: "/buy-nft",
    menu: "/buy-nft",
    title: "Real Estate",
    des: "Location owner will earn 10% of NOW from all community of users that visit the location To Earn Now 10% of rental fees for all virtual machines place at the location.   An additional 25% of shared revenue for actual advertising contract at the location ",
  },
  {
    img: Trusted2,
    link: "/buy-nft",
    menu: "VirtualMachine",
    title: "Virtual Machine",
    des: "Owner of virtual machine will earning an additional 10% from the community that visit the same location",
  },
  {
    img: Trusted3,
    link: "/buy-nft",
    menu: "card",
    title: "NFT Card",
    des: " Earn an additional 11% -15 %. Depending on type of card",
  },
];
