import { Des, Progress, Title } from "./styles";
import { fixedNumber, formatMoney } from "components/FormatNumber";
import { useSelector } from "react-redux";

const Mana = () => {
  const { blockNow } = useSelector((state) => state.ico);
  const endDate = new Date(blockNow?.end_date);
  const startDate = new Date(blockNow?.start_date);
  const totalDate = new Date(endDate - startDate) / (24 * 60 * 60 * 1000);
  const dateDone = new Date(Date.now() - startDate) / (24 * 60 * 60 * 1000);
  const progressDate = (dateDone / totalDate) * 100;
  const progressNFT =
    (blockNow?.total_sold / (blockNow?.total + blockNow?.total_sold)) * 100;

  const calculatorProgress = (progressNFT) => {
    if (progressNFT > 0 && progressNFT < 5) {
      return 5;
    } else {
      return progressNFT;
    }
  };

  return (
    <>
      <Title>Location Specific Earning Schedule</Title>
      <Des>(Mint as Real Estate NFT with exact longitude and latitude)</Des>
      <Progress.Main>
        {/* <Progress.Item>
          <Progress.Mana>
            <Progress.Done
              style={{ width: progressDate ? progressDate + "%" : "0" }}
            ></Progress.Done>
          </Progress.Mana>
          <Progress.Text>
            <p>
              {dateDone ? formatMoney(String(fixedNumber(dateDone, 0))) : "..."}{" "}
              days
            </p>
            <p>{totalDate ? totalDate : "..."} days</p>
          </Progress.Text>
        </Progress.Item> */}

        <Progress.Item>
          <Progress.Mana>
            <Progress.Done
              style={{
                width: calculatorProgress(progressNFT)
                  ? calculatorProgress(progressNFT) + "%"
                  : "0",
              }}
            ></Progress.Done>
          </Progress.Mana>
          <Progress.Text>
            <p>{blockNow ? blockNow?.total_sold : "..."} NFT</p>
            <p>
              MAX {blockNow ? blockNow?.total + blockNow?.total_sold : "..."}
            </p>
          </Progress.Text>
        </Progress.Item>
      </Progress.Main>
    </>
  );
};

export default Mana;
