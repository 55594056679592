import { createSlice } from "@reduxjs/toolkit";
import CreateApi from "api/CreateApi";
import { MessageToastError, MessageToastSuccess } from "components/Message";

const initialState = {
  getStatusProfilePage: "",
  loadding: false,
};

export const setStatusProfilePage = (data) => {
  return async (dispatch) => {
    try {
      dispatch(SetStatusProfilePage(data));
    } catch (error) {}
  };
};

export const postContact = (data) => {
  return async (dispatch) => {
    dispatch(Loadding(true));
    try {
      let res = await CreateApi.CreateApiS(
        `public/support/save-support`,
        { ...data },
        true
      );
      if (res?.data?.status === true) {
        MessageToastSuccess(res?.data?.message);
        dispatch(Loadding(false));
      } else {
        MessageToastError(res?.data?.message);

        dispatch(Loadding(false));
      }
    } catch (error) {
      dispatch(Loadding(false));
    }
  };
};

const ortherSlice = createSlice({
  name: "view",
  initialState,
  reducers: {
    SetStatusProfilePage(state, action) {
      return {
        ...state,
        getStatusProfilePage: action.payload,
      };
    },
    Loadding(state, action) {
      return {
        ...state,
        loadding: action.payload,
      };
    },
  },
});

const { reducer, actions } = ortherSlice;
export const { SetStatusProfilePage, Loadding } = actions;
export default reducer;
