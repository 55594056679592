import { Item, NFTLocationStyles, Pagi } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Box, CircularProgress, Pagination } from "@mui/material";
import bg from "assets/svg/bg-Trusted.svg";
import EmptyData from "components/EmptyData";
import { fixedNumber, formatMoneyDot } from "components/FormatNumber";
import { SellNFTLocation } from "components/Message";
import _ from "lodash";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  handleCancelSellNFTLocation,
  handleGetMyRealEstate,
} from "redux/slices/nft";
import { CheckImage, getColor } from "utits/auth";

const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      padding: "10px",
    },
    root: {
      "& .Mui-selected": {
        background: "#a100a5!important",
        color: "white",
      },
      "& ul > li > button": {
        backgroundColor: "white",
        color: "black",
      },
    },
  })
);
const NFTLocation = ({ listMarketPlace }) => {
  const [page, setPage] = useState(1);
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { listMyRealEstate, loadding, loaddingSell } = useSelector(
    (state) => state.nft
  );

  useEffect(() => {
    dispatch(handleGetMyRealEstate());
  }, []);

  const hanldeCancelSell = (id) => {
    dispatch(handleCancelSellNFTLocation(id));
  };
  return (
    <>
      <NFTLocationStyles.Main>
        <NFTLocationStyles.Container className="container">
          {listMyRealEstate?.length === 0 && !loadding && <EmptyData />}
          {listMyRealEstate?.length &&
            listMyRealEstate?.map((item, index) => (
              <Item.Main key={index}>
                <Item.Container>
                  <Item.Head
                    style={{
                      background: `url(${CheckImage(item?.images[0])})`,
                    }}
                  >
                    {item?.isSell ? (
                      <Item.Price>
                        ${formatMoneyDot(String(fixedNumber(item?.isPrice, 2)))}
                      </Item.Price>
                    ) : (
                      <Item.Price></Item.Price>
                    )}

                    <Item.Content>
                      <Item.Category>{item?.category}</Item.Category>
                      <Item.Name>{item?.name}</Item.Name>
                      <Item.Address>{item?.address}</Item.Address>
                      <Item.Ratting>
                        {_.times(5, (i) => (
                          <FontAwesomeIcon
                            icon="star"
                            style={{ color: getColor(item?.rating, i) }}
                          />
                        ))}
                      </Item.Ratting>
                    </Item.Content>
                    <Item.Overlay></Item.Overlay>
                  </Item.Head>
                </Item.Container>

                <Item.Bottom>
                  <Item.Detail onClick={() => navigate(`/maps/${item?.id}`)}>
                    Detail
                  </Item.Detail>
                  {Boolean(item?.isSell) ? (
                    <Item.Cancel onClick={() => hanldeCancelSell(item?.id)}>
                      {loaddingSell === item?.id ? (
                        <CircularProgress
                          style={{
                            width: "15px",
                            height: "15px",
                            color: "white",
                          }}
                        />
                      ) : (
                        "Cancel"
                      )}
                    </Item.Cancel>
                  ) : (
                    <Item.Buy
                      onClick={() => SellNFTLocation(dispatch, item?.id)}
                      disabled={loaddingSell ? true : false}
                    >
                      {loaddingSell === item?.id ? (
                        <CircularProgress
                          style={{
                            width: "15px",
                            height: "15px",
                            color: "white",
                          }}
                        />
                      ) : (
                        "Sell"
                      )}
                    </Item.Buy>
                  )}
                </Item.Bottom>
              </Item.Main>
            ))}
          {loadding && (
            <CircularProgress
              style={{
                width: "30px",
                height: "30px",
                color: "white",
                margin: "auto",
              }}
            />
          )}
          <img
            src={bg}
            alt=""
            style={{
              position: "absolute",
              top: "0",
              left: "-25%",
              width: "70%",
              overflow: "hidden",
            }}
          />
        </NFTLocationStyles.Container>
      </NFTLocationStyles.Main>
      {listMarketPlace?.total > 16 && (
        <Pagi>
          <Box className={classes.box}>
            <Pagination
              count={
                listMarketPlace?.total
                  ? Math.ceil(listMarketPlace?.total / 16)
                  : 0
              }
              size="large"
              onChange={(e, p) => setPage(p)}
              className={classes.root}
            />
          </Box>
        </Pagi>
      )}
    </>
  );
};
export default NFTLocation;
