import { createSlice } from "@reduxjs/toolkit";
import CreateApi from "api/CreateApi";
import { ErrorMessage, SuccessMessage } from "components/Message";

const initialState = {
  getLoadding: false,
  listReview: [],
  createReview: [],
};

export const handleGetReview = (data) => {
  const { locationid, limit } = data;
  return async (dispatch) => {
    dispatch(GetLoading(true));

    try {
      let res = await CreateApi.CreateApiGET(
        `public/review/get-review?locationid=${locationid}&limit=${limit}`,
        true
      );
      if (res?.data?.status == true) {
        dispatch(ListReview(res?.data?.data));
        // SuccessMessage("Exchange Success");
      } else {
        // ErrorMessage(res?.data?.message);
      }
    } catch (error) {
      //   ErrorMessage("Please check again");
    }
    dispatch(GetLoading(false));
  };
};
export const handleCreateReview = (data) => {
  return async (dispatch) => {
    dispatch(GetLoading(true));

    try {
      let res = await CreateApi.CreateApiS(
        `public/review/save-review`,
        data,
        "OXvdkeYkrRaLSd7Pljc7VuX-EjWaCgWA"
      );
      if (res?.data?.status == true) {
        dispatch(CreateReview(res?.data?.data));
        SuccessMessage("Successfully !");
        dispatch(handleGetReview({ locationid: data.locationid, limit: 5 }));
      } else {
        // ErrorMessage(res?.data?.message);
      }
    } catch (error) {
      //   ErrorMessage("Please check again");
    }
    dispatch(GetLoading(false));
  };
};

const reviewSlice = createSlice({
  name: "review",
  initialState,
  reducers: {
    GetLoading: (state, action) => {
      return {
        ...state,
        getLoadding: action.payload,
      };
    },
    ListReview: (state, acction) => {
      return {
        ...state,
        listReview: acction.payload,
      };
    },
    CreateReview: (state, acction) => {
      return {
        ...state,
        createReview: acction.payload,
      };
    },
  },
});

const { reducer, actions } = reviewSlice;
export const { GetLoading, ListReview, CreateReview } = actions;
export default reducer;
