import { Overlay } from "../auth";
import { createSlice } from "@reduxjs/toolkit";
import CreateApi from "api/CreateApi";
import { ErrorMessage, SuccessMessage } from "components/Message";

const initialState = {
  getLoadding: false,
  listMarketPlace: [],
  myLocation: [],
};

export const handleListMarketPlace = (data) => {
  const { page, cateloryid, locationid, keywords } = data;
  return async (dispatch) => {
    dispatch(GetLoading(true));

    try {
      let res = await CreateApi.CreateApiGET(
        `public/market/list-market?cateloryid=${cateloryid}&locationid=${locationid}&keywords=${keywords}&limit=12&page=${page}`,
        true
      );
      if (res?.data?.status === true) {
        dispatch(ListMarketPlace(res?.data?.data));
        // SuccessMessage("Exchange Success");
      } else {
        // ErrorMessage(res?.data?.message);
      }
    } catch (error) {
      //   ErrorMessage("Please check again");
    }
    dispatch(GetLoading(false));
  };
};

export const handleGetMyLocation = (data) => {
  const { page } = data;
  return async (dispatch) => {
    dispatch(GetLoading(true));

    try {
      let res = await CreateApi.CreateApiSSS(`public/market/my-location`, true);
      if (res?.data?.status === true) {
        dispatch(MyLocation(res?.data?.data));
        // SuccessMessage("Exchange Success");
      } else {
        // ErrorMessage(res?.data?.message);
      }
    } catch (error) {
      //   ErrorMessage("Please check again");
    }
    dispatch(GetLoading(false));
  };
};

const mapsSlice = createSlice({
  name: "marketplace",
  initialState,
  reducers: {
    GetLoading: (state, action) => {
      return {
        ...state,
        getLoadding: action.payload,
      };
    },
    ListMarketPlace: (state, acction) => {
      return {
        ...state,
        listMarketPlace: acction.payload,
      };
    },
    MyLocation: (state, acction) => {
      return {
        ...state,
        myLocation: acction.payload,
      };
    },
  },
});

const { reducer, actions } = mapsSlice;
export const { GetLoading, ListMarketPlace, MyLocation } = actions;
export default reducer;
