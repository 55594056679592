import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import CreateApi from "api/CreateApi";
import {
  ErrorMessage,
  MessageToastError,
  MessageToastSuccess,
  SuccessMessage,
} from "components/Message";

const initialState = {
  groupMining: false,
  childrenDrawer: false,
  listtime: [],
  checkMining: [],
  checkTimeMining: false,
  finishMining: false,
  startMining: false,
  finishh: [],
  loadding: false,
  checkInNormal: [],
  checkOutNormal: [],
  getCheckIn: [],
  checkTimeMiningCard: false,
  rewardEarning: [],
  startMiningCard: false,
};

export const handleGetListTime = () => {
  return async (dispatch) => {
    try {
      let res = await CreateApi.CreateApiSSS(
        "public/minning/timming?coin=NOW",
        true
      );
      dispatch(ListTime(res?.data?.data));
    } catch (error) {}
  };
};

export const handleStartMinning = (
  time,
  type,
  id,
  type_range,
  setCheckedState
) => {
  const data = {
    time: time,
    type: type,
    locationid: id,
    type_range: type_range,
  };
  return async (dispatch) => {
    dispatch(Loadding(true));
    try {
      let res = await CreateApi.CreateApi1(`public/minning/start`, data, true);
      if (res?.data?.status === true) {
        setCheckedState(true);
        dispatch(StartMining(true));
        dispatch(handleCheckTimeMining({ type_earn: "normal" }));
        dispatch(Loadding(false));
      } else {
        dispatch(StartMining(false));
        MessageToastError(res?.data?.message);
        dispatch(Loadding(false));
      }
    } catch (error) {
      dispatch(Loadding(false));
    }
  };
};
export const handleStartMinningCard = (
  time,
  type,
  id,
  type_range,
  setCheckedState
) => {
  const data = {
    time: time,
    type: type,
    locationid: id,
    type_range: type_range,
  };
  // const data = {
  //   time: 30,
  //   type: "second",
  //   locationid: id,
  //   type_range: type_range,
  // };
  return async (dispatch) => {
    dispatch(Loadding(true));
    try {
      let res = await CreateApi.CreateApi1(
        `public/minning/start-card`,
        data,
        true
      );
      if (res?.data?.status === true) {
        dispatch(handleCheckTimeMining({ type_earn: "card" }));
        setCheckedState(true);
        dispatch(Loadding(false));
      } else {
        dispatch(StartMiningCard(false));
        setCheckedState(false);
        MessageToastError(res?.data?.message);
        dispatch(Loadding(false));
      }
    } catch (error) {
      dispatch(Loadding(false));
    }
  };
};
export const handleCheckIn = (data) => {
  return async (dispatch) => {
    dispatch(Loadding(true));
    try {
      let res = await CreateApi.CreateApi1(
        `public/minning/check-in`,
        data,
        true
      );
      if (res?.data?.status === true) {
        dispatch(CheckInNormal(res?.data?.data));
        dispatch(handleGetCheckIn({ type_earn: data?.type_earn }));
        dispatch(Loadding(false));
      } else {
        dispatch(Loadding(false));
        MessageToastError(res?.data?.message);
      }
    } catch (error) {
      dispatch(Loadding(false));
    }
  };
};

export const handleGetCheckIn = (data) => {
  const { type_earn } = data;
  return async (dispatch) => {
    dispatch(Loadding(true));
    try {
      let res = await CreateApi.CreateApiSSS(
        `public/minning/get-check-in?type_earn=${type_earn}`,

        true
      );
      if (res?.data?.status === true) {
        dispatch(GetCheckIn(res?.data?.data[0]));
        dispatch(Loadding(false));
      } else {
        dispatch(Loadding(false));
        MessageToastError(res?.data?.message);
      }
    } catch (error) {
      dispatch(Loadding(false));
    }
  };
};

export const handleCheckOut = (data) => {
  return async (dispatch) => {
    dispatch(Loadding(true));
    try {
      let res = await CreateApi.CreateApi1(
        `public/minning/check-out`,
        data,
        true
      );
      if (res?.data?.status === true) {
        dispatch(CheckOutNormal(res?.data?.data));
        dispatch(handleGetCheckIn({ type_earn: data?.type_earn }));
        dispatch(Loadding(false));
      } else {
        dispatch(Loadding(false));
        MessageToastError(res?.data?.message);
      }
    } catch (error) {
      dispatch(Loadding(false));
    }
  };
};

export const handleFinish = (locationid, type_range, setCheckedState) => {
  const data = {
    locationid: locationid,
    type_range: type_range,
  };
  return async (dispatch) => {
    dispatch(Loadding(true));
    try {
      let res = await CreateApi.CreateApi1(
        `public/minning/minning`,
        data,
        true
      );
      if (res?.data?.status === true) {
        setCheckedState(false);
        dispatch(Loadding(false));
        MessageToastSuccess("Successful income!!");
        dispatch(FinishMining(true));
        dispatch(Finish(res?.data?.data));
      } else {
        dispatch(Loadding(false));

        MessageToastError(res?.data?.message);
      }
    } catch (error) {
      dispatch(Loadding(false));
    }
  };
};
export const handleCheckTimeMining = ({ type_earn }) => {
  return async (dispatch) => {
    try {
      let res = await CreateApi.CreateApiSSS(
        `public/minning/time-minning?type_earn=${type_earn}`,
        true
      );
      if (res?.data?.status === false) dispatch(CheckTimeMining(false));
      else {
        if (type_earn === "normal") {
          dispatch(CheckTimeMining(res?.data?.data));
        } else if (type_earn === "card") {
          dispatch(CheckTimeMiningCard(res?.data?.data));
        }
      }
    } catch (error) {}
  };
};
export const handleGetRewardEarning = () => {
  return async (dispatch) => {
    try {
      let res = await CreateApi.CreateApiSSS(
        "public/minning/config-minning",
        true
      );
      dispatch(RewardEarning(res?.data?.data));
    } catch (error) {}
  };
};
export const handleCheckMining = (
  myLatiude,
  myLongitude,
  locaLatiude,
  locaLongitude
) => {
  return async (dispatch) => {
    try {
      let res = await CreateApi.CreateApiSSS(
        `public/minning/check-minning?myLatiude=${myLatiude}&myLongitude=${myLongitude}&locaLatiude=${locaLatiude}&locaLongitude=${locaLongitude}`,
        true
      );
      dispatch(CheckMining(res?.data));
    } catch (error) {}
  };
};

const miningSlice = createSlice({
  name: "maps",
  initialState,
  reducers: {
    GroupMining: (state, action) => {
      return {
        ...state,
        groupMining: action.payload,
      };
    },
    ListTime: (state, action) => {
      return {
        ...state,
        listTime: action.payload,
      };
    },
    CheckMining: (state, action) => {
      return {
        ...state,
        checkMining: action.payload,
      };
    },
    FinishMining: (state, action) => {
      return {
        ...state,
        finishMining: action.payload,
      };
    },
    ChildrenDrawer: (state, action) => {
      return {
        ...state,
        childrenDrawer: action.payload,
      };
    },
    CheckTimeMining: (state, action) => {
      return {
        ...state,
        checkTimeMining: action.payload,
      };
    },
    CheckTimeMiningCard: (state, action) => {
      return {
        ...state,
        checkTimeMiningCard: action.payload,
      };
    },
    StartMining: (state, action) => {
      return {
        ...state,
        startMining: action.payload,
      };
    },
    Finish: (state, action) => {
      return {
        ...state,
        finishh: action.payload,
      };
    },
    CheckInNormal: (state, action) => {
      return {
        ...state,
        checkInNormal: action.payload,
      };
    },
    CheckOutNormal: (state, action) => {
      return {
        ...state,
        checkOutNormal: action.payload,
      };
    },
    GetCheckIn: (state, action) => {
      return {
        ...state,
        getCheckIn: action.payload,
      };
    },
    Loadding: (state, action) => {
      return {
        ...state,
        loadding: action.payload,
      };
    },
    RewardEarning: (state, action) => {
      return {
        ...state,
        rewardEarning: action.payload,
      };
    },
    StartMiningCard: (state, action) => {
      return {
        ...state,
        startMiningCard: action.payload,
      };
    },
  },
});

const { reducer, actions } = miningSlice;
export const {
  GroupMining,
  ChildrenDrawer,
  ListTime,
  CheckMining,
  CheckTimeMining,
  CheckTimeMiningCard,
  RewardEarning,
  FinishMining,
  StartMining,
  Finish,
  Loadding,
  CheckInNormal,
  CheckOutNormal,
  GetCheckIn,
  StartMiningCard,
} = actions;
export default reducer;
