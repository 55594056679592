/* eslint-disable react/jsx-no-target-blank */
import FormProvider from "./FormProvider";
import RHFTextField from "./RHFTextField";
import { Content, Wrapper } from "./styles";
import FacebookIcon from "@mui/icons-material/Facebook";
import TelegramIcon from "@mui/icons-material/Telegram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { LoadingButton } from "@mui/lab";
import { InputAdornment, Stack, Typography } from "@mui/material";
import CreateApi from "api/CreateApi";
import Bg from "assets/svg/drawer/airdrop.svg";
import img from "assets/svg/drawer/airdropGitf.svg";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { handleSendAirdrop } from "redux/slices/airdrop";
import ThemeProviderCustom from "theme";
import { GetUser, SetUser } from "utits/auth";

const Airdrop = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.airdrop);

  const methods = useForm({});

  const { reset, setValue, setError, handleSubmit } = methods;

  const regex = /^(ftp|http|https):\/\/[^ "]+$/;
  const onSubmit = async (data) => {
    try {
      dispatch(
        handleSendAirdrop(
          {
            ...data,
            username: GetUser()?.username,
            userid: GetUser()?.id || 1,
          },
          reset
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await CreateApi.CreateApiSSS("public/user/userinfo", true);
        SetUser(user?.data?.data, dispatch);
      } catch (error) {
        // Xử lý lỗi tại đây nếu cần thiết
      }
    };

    fetchData();
  }, []);

  return (
    <ThemeProviderCustom>
      <Wrapper.section
        style={{
          background: `url(${Bg})`,
          backgroundSize: "cover",
        }}
      >
        <div
          className="container"
          style={{ position: "relative", zIndex: "2" }}
        >
          <Wrapper.Main data-aos="fade-up">
            <Wrapper.Line></Wrapper.Line>
            <Wrapper.Text>COMMUTITY ENGAGEMENT PROGRAM</Wrapper.Text>

            <Wrapper.Line></Wrapper.Line>
          </Wrapper.Main>
          <Stack direction="column" justifyContent="center" alignItems="center">
            {GetUser()?.airdrop_status === 0 && (
              <Typography
                sx={{
                  color: "#ffffff",
                  p: { xs: 2, sm: 3 },
                  wordBreak: "break-work",
                  textAlign: "center",
                  fontWeight: "700",
                  maxWidth: "1000px",
                  fontSize: {
                    xs: "18px",
                    sm: "20px",
                    md: "22px",
                  },
                }}
              >
                Your task is pending moderation. Please wait for the results!
              </Typography>
            )}
            {GetUser()?.airdrop_status === 1 && (
              <Typography
                sx={{
                  color: "#ffffff",
                  p: { xs: 2, sm: 3 },
                  wordBreak: "break-work",
                  textAlign: "center",
                  fontWeight: "700",
                  maxWidth: "1000px",
                  fontSize: {
                    xs: "18px",
                    sm: "20px",
                    md: "22px",
                  },
                }}
              >
                Congratulations, you have completed the task!
              </Typography>
            )}
            {GetUser()?.airdrop_status === 2 && (
              <Typography
                sx={{
                  color: "#ffffff",
                  p: { xs: 2, sm: 3 },
                  wordBreak: "break-work",
                  textAlign: "center",
                  fontWeight: "700",
                  maxWidth: "1000px",
                  fontSize: {
                    xs: "18px",
                    sm: "20px",
                    md: "22px",
                  },
                }}
              >
                Your task has been cancelled. Please do it again later!
              </Typography>
            )}
            {GetUser()?.airdrop_status === null && (
              <>
                <Typography
                  sx={{
                    color: "#ffffff",
                    p: { xs: 2, sm: 3 },
                    wordBreak: "break-work",
                    textAlign: "center",
                    fontWeight: "700",
                    maxWidth: "1000px",
                    fontSize: {
                      xs: "16px",
                      sm: "20px",
                      md: "22px",
                    },
                  }}
                >
                  Congratulations on your incredible achievements in building,
                  earning, and growing! Now, it's time to unlock even greater
                  possibilities! By completing the following tasks, you can
                  unlock the power to transfer or withdraw your earnings. It's
                  time to seize this opportunity and level up! Remember, all
                  users are qualified, but make sure to complete your KYC for
                  eligibility.
                </Typography>
                <Typography
                  sx={{
                    color: "#ffffff",
                    p: 2,
                    wordBreak: "break-work",
                    textAlign: "center",
                    fontWeight: "500",
                    maxWidth: "600px",
                    fontSize: {
                      xs: "14px",
                      sm: "16px",
                      md: "18px",
                    },
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      color: "#e30273",
                      wordBreak: "break-work",
                      textAlign: "center",
                      fontWeight: "700",
                      maxWidth: "600px",
                      fontSize: {
                        xs: "14px",
                        sm: "16px",
                        md: "18px",
                      },
                    }}
                  >
                    Step 1:
                  </Typography>{" "}
                  Twitter <br />{" "}
                  <a
                    style={{ fontStyle: "italic" }}
                    target="_blank"
                    href="https://twitter.com/toearnnow?s=21&t=_sFF4qF8bFhkNCmSKnt2Kg"
                    title="Toearnnow on Twitter"
                  >
                    Follow @Toearnnow on twitter
                  </a>
                  <a
                    style={{ fontStyle: "italic" }}
                    target="_blank"
                    href="https://twitter.com/toearnnow/status/1647784992758722560?s=46&t=_sFF4qF8bFhkNCmSKnt2Kg"
                    title="Toearnnow on Twitter"
                  >
                    {" "}
                    Like & retweet + Tag 3 friends
                  </a>
                  <br />
                  <Typography
                    component="span"
                    sx={{
                      color: "#e30273",
                      wordBreak: "break-work",
                      textAlign: "center",
                      fontWeight: "700",
                      maxWidth: "600px",
                      fontSize: {
                        xs: "14px",
                        sm: "16px",
                        md: "18px",
                      },
                    }}
                  >
                    Step 2:
                  </Typography>{" "}
                  Facebook <br />{" "}
                  <a
                    style={{ fontStyle: "italic" }}
                    target="_blank"
                    href="https://www.facebook.com/profile.php?id=100086592356812&mibextid=LQQJ4d"
                    title="Toearnnow on Facebook"
                  >
                    {" "}
                    Follow To Earn Now on facebook
                  </a>
                  <a
                    style={{ fontStyle: "italic" }}
                    target="_blank"
                    href="https://fb.watch/lnO_r3n5Qk/?mibextid=qC1gEa"
                    title="Toearnnow on Facebook"
                  >
                    {" "}
                    Like + Share post Toearnnow + Tag 3 friends{" "}
                  </a>
                  <br />
                  <Typography
                    component="span"
                    sx={{
                      color: "#e30273",
                      wordBreak: "break-work",
                      textAlign: "center",
                      fontWeight: "700",
                      maxWidth: "600px",
                      fontSize: {
                        xs: "14px",
                        sm: "16px",
                        md: "18px",
                      },
                    }}
                  >
                    Step 3:
                  </Typography>{" "}
                  Youtube <br />{" "}
                  <a
                    style={{ fontStyle: "italic" }}
                    target="_blank"
                    href="https://youtube.com/@toearnnow4309"
                    title="Toearnnow on Youtube"
                  >
                    {" "}
                    Subscribe To Earn Now on Youtube{" "}
                  </a>
                  <br />
                  <Typography
                    component="span"
                    sx={{
                      color: "#e30273",
                      wordBreak: "break-work",
                      textAlign: "center",
                      fontWeight: "700",
                      maxWidth: "600px",
                      fontSize: {
                        xs: "14px",
                        sm: "16px",
                        md: "18px",
                      },
                    }}
                  >
                    Step 4:
                  </Typography>{" "}
                  Telegram <br />
                  <a
                    style={{ fontStyle: "italic" }}
                    target="_blank"
                    href="https://t.me/toEarnNOW_com"
                    title="Toearnnow on Telegram"
                  >
                    {" "}
                    Follow To Earn Now Telegram channel{" "}
                  </a>
                </Typography>{" "}
              </>
            )}
          </Stack>
          {GetUser()?.airdrop_status === null && (
            <Content.Main>
              <Content.Left>
                <img src={img} alt="" />
              </Content.Left>

              <Content.Right>
                <FormProvider
                  methods={methods}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <RHFTextField
                    name="twitter"
                    label="Twitter"
                    onChange={(event) => {
                      const value = event.target.value;
                      if (regex.test(value)) {
                        setValue("twitter", value, { shouldValidate: true });
                        setError("twitter", null);
                      } else if (value.trim() === "") {
                        setValue("twitter", null, { shouldValidate: true });
                      } else {
                        setError("twitter", {
                          type: "invalid",
                          message: "Invalid URL",
                        });
                      }
                    }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <TwitterIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <RHFTextField
                    name="facebook"
                    label="Facebook"
                    required
                    onChange={(event) => {
                      const value = event.target.value;
                      if (regex.test(value)) {
                        setValue("facebook", value, { shouldValidate: true });
                        setError("facebook", null);
                      } else if (value.trim() === "") {
                        setValue("facebook", null, { shouldValidate: true });
                      } else {
                        setError("facebook", {
                          type: "invalid",
                          message: "Invalid URL",
                        });
                      }
                    }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FacebookIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <RHFTextField
                    name="youtube"
                    label="Youtube"
                    onChange={(event) => {
                      const value = event.target.value;
                      if (regex.test(value)) {
                        setValue("youtube", value, { shouldValidate: true });
                        setError("youtube", null);
                      } else if (value.trim() === "") {
                        setValue("youtube", null, { shouldValidate: true });
                      } else {
                        setError("youtube", {
                          type: "invalid",
                          message: "Invalid URL",
                        });
                      }
                    }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <YouTubeIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <RHFTextField
                    name="telegram"
                    required
                    label="Telegram"
                    onChange={(event) => {
                      const value = event.target.value;
                      if (regex.test(value)) {
                        setValue("telegram", value, { shouldValidate: true });
                        setError("telegram", null);
                      } else if (value.trim() === "") {
                        setValue("telegram", null, { shouldValidate: true });
                      } else {
                        setError("telegram", {
                          type: "invalid",
                          message: "Invalid URL",
                        });
                      }
                    }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <TelegramIcon />
                        </InputAdornment>
                      ),
                    }}
                  />

                  {/* <RHFTextField
                    name="instagram"
                    label="Instagram"
                    onChange={(event) => {
                      const value = event.target.value;
                      if (regex.test(value)) {
                        setValue("instagram", value, { shouldValidate: true });
                        setError("instagram", null);
                      } else if (value.trim() === "") {
                        setValue("instagram", null, { shouldValidate: true });
                      } else {
                        setError("instagram", {
                          type: "invalid",
                          message: "Invalid URL",
                        });
                      }
                    }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <InstagramIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <RHFTextField
                    name="linkedin"
                    required
                    label="Linkedin"
                    onChange={(event) => {
                      const value = event.target.value;
                      if (regex.test(value)) {
                        setValue("linkedin", value, { shouldValidate: true });
                        setError("linkedin", null);
                      } else if (value.trim() === "") {
                        setValue("linkedin", null, { shouldValidate: true });
                      } else {
                        setError("linkedin", {
                          type: "invalid",
                          message: "Invalid URL",
                        });
                      }
                    }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LinkedInIcon />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <RHFTextField
                    name="reddit"
                    label="Reddit"
                    onChange={(event) => {
                      const value = event.target.value;
                      if (regex.test(value)) {
                        setValue("reddit", value, { shouldValidate: true });
                        setError("reddit", null);
                      } else if (value.trim() === "") {
                        setValue("reddit", null, { shouldValidate: true });
                      } else {
                        setError("reddit", {
                          type: "invalid",
                          message: "Invalid URL",
                        });
                      }
                    }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <RedditIcon />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <RHFTextField
                    name="discord"
                    label="Discord"
                    onChange={(event) => {
                      const value = event.target.value;
                      if (regex.test(value)) {
                        setValue("discord", value, { shouldValidate: true });
                        setError("discord", null);
                      } else if (value.trim() === "") {
                        setValue("discord", null, { shouldValidate: true });
                      } else {
                        setError("discord", {
                          type: "invalid",
                          message: "Invalid URL",
                        });
                      }
                    }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AndroidIcon />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <RHFTextField
                    name="tiktok"
                    label="Tiktok"
                    onChange={(event) => {
                      const value = event.target.value;
                      if (regex.test(value)) {
                        setValue("tiktok", value, { shouldValidate: true });
                        setError("tiktok", null);
                      } else if (value.trim() === "") {
                        setValue("tiktok", null, { shouldValidate: true });
                      } else {
                        setError("tiktok", {
                          type: "invalid",
                          message: "Invalid URL",
                        });
                      }
                    }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SVG name="drawer/tiktok" style={{ width: "23px" }} />
                        </InputAdornment>
                      ),
                    }}
                  /> */}

                  <LoadingButton
                    type="submit"
                    variant="contained"
                    size="large"
                    loading={isLoading}
                    sx={{
                      mt: 2,
                      mb: 3,
                      width: "100%",
                      background:
                        "linear-gradient(90deg,#780d69 0%,#ec0174 100%);",
                    }}
                  >
                    Submit
                  </LoadingButton>
                </FormProvider>
              </Content.Right>
            </Content.Main>
          )}
        </div>
      </Wrapper.section>
    </ThemeProviderCustom>
  );
};
export default Airdrop;
