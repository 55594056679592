import React from "react";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { TextField } from "@mui/material";

import { GetValue2fa } from "redux/slices/auth";


const textFiled = {
    background: "white",
    borderRadius: "5px",
};
export default function RecaptchaF2A() {

    let { value2fa } = useSelector(state => state.auth);
    // let reRef = useRef();
    // let reCaptcha = useRef();
    // const { getprofile } = useSelector((state) => state.auth);
    // const twofa = getprofile?.enabled_twofa;
    let dispatch = useDispatch();

    // const [token, setStateToken] = useState("");
    // function ErroRepcatcha(token) {
    //     if (token) {
    //         dispatch(GetTokenRep(token));
    //     } else {
    //         dispatch(GetTokenRep(""));
    //     }
    // }
    const handleChange2FA = (e) => {
        dispatch(GetValue2fa(e.target.value));
    };

    return (
        <div >
            {/* {twofa == 0 ? (
                <ReCAPTCHA
                    ref={reRef}
                    sitekey="6LcETjYfAAAAAPMzAgV2ZhJDPziORVr5gJyin8fm"
                    onChange={async (token) => {
                        ErroRepcatcha(token);
                    }}
                    onExpired={(e) => {
                        setStateToken("");
                    }}
                />
            ) : ( */}
            
            <div>
                <TextField
                    sx={textFiled}
                    fullWidth
                    label="2FA"
                    id="fullWidth"
                    onChange={(e) => handleChange2FA(e)}
                    style={{ zIndex: "0", fontWeight: "bold", color: "#0344FF" }}
                />
            </div>
            {/* )} */}
        </div>
    );
}
