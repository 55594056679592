import { themes } from "common/styles/theme";
import styled from "styled-components";

export const Wrapper = styled.section`
  font-family: "Roboto";
  min-height: 80vh;
  height: 100%;
  ${themes.mixin.flexColCenter()};
  flex-direction: column;
  gap: 50px;
  overflow: scroll;
  padding-bottom: 30px;
`;
export const Content = {
  main: styled.div`
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.16);
    border-radius: 18px;
  `,
  title: styled.div`
    background: ${themes.colors.primary};
    border-radius: 20px 20px 0px 0px;
    display: flex;
    height: 50px;

    > div {
      cursor: pointer;
      width: 130px;
      text-align: center;
      ${themes.mixin.flexCenter()};
      border-radius: 20px 20px 0px 0px;
      color: ${themes.colors.white};
      font-weight: 700;
      text-transform: uppercase;
      &.active {
        background: ${themes.colors.wallet};
      }
    }
  `,
};
