import { ContainerStyledx, Sectionx } from "./Banner.Styled";
import { ContainerStyled, Section, Token, Wrapper } from "./styled";
import { Card, Grid, Stack } from "@mui/material";
import TokenS from "assets/img/allocation-token.svg";
import ReactPlayer from "react-player";

const data = [
  {
    name: "Name",
    value: "TOEARNNOW",
  },
  {
    name: "Type",
    value: "BEP20",
  },
  {
    name: "Symbol",
    value: "NOW",
  },
  {
    name: "Platform",
    value: "Binance Smart Chain",
  },
  {
    name: "General release",
    value: "10.000.000.000",
  },
];

const styles = {
  card: {
    margin: "120px auto 50px",
    maxWidth: 345,
    overflow: "visible",
  },
  media: {
    margin: "-70px auto 0",
    width: "80%",
    height: 140,
    borderRadius: "4px",
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)",
    position: "relative",
    zIndex: 1000,
  },
};

export default function Allocation() {
  return (
    <Section>
      <ContainerStyled>
        <Wrapper.main>
          <h1 data-aos="fade-up" data-aos-offset="30">
            This is<strong> NOW</strong>
          </h1>
        </Wrapper.main>
        <Sectionx>
          <Card
            sx={{
              mt: { xs: 0, sm: 3 },
              borderRadius: "18px",
              border: "2px solid rgba(106,49,129,0.2)",
              background:
                "linear-gradient( 115.36deg, rgba(80,45,159,0.4) 33.92%, rgba(8,2,28,0) 99.86% )",
            }}
          >
            <Stack spacing={2} sx={{ my: 3 }} direction="column">
              <ContainerStyledx>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    data-aos="fade-up"
                    data-aos-offset="30"
                  >
                    <ReactPlayer
                      url="https://www.youtube.com/embed/oXCDBwYpsvI"
                      className="playerEmbed"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    data-aos="fade-up"
                    data-aos-offset="30"
                  >
                    <ReactPlayer
                      url="https://www.youtube.com/embed/IdDSr5EAgeI"
                      className="playerEmbed"
                    />
                  </Grid>
                </Grid>
              </ContainerStyledx>
              <ContainerStyledx>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    data-aos="fade-up"
                    data-aos-offset="30"
                  >
                    <ReactPlayer
                      url="https://www.youtube.com/embed/fCLBg3X_Dtc"
                      className="playerEmbed"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    data-aos="fade-up"
                    data-aos-offset="30"
                  >
                    <ReactPlayer
                      url="https://www.youtube.com/embed/oVkMK63gzQU"
                      className="playerEmbed"
                    />
                  </Grid>
                </Grid>
              </ContainerStyledx>
            </Stack>
          </Card>
        </Sectionx>

        <Token.main>
          <Token.wrapper data-aos="fade-up" data-aos-offset="30">
            <div
              style={{
                display: "flex",
                flexDirection: "initial",
                display: "flex",
                alignItems: "center",
                gap: "50px",
              }}
            >
              <img src={TokenS} alt="" style={{ width: "72px" }} />
              <h1>NOW Token</h1>
            </div>
            <div>
              {data.map((e, i) => (
                <div key={i}>
                  <span>{e.name}</span>
                  <strong>{e.value}</strong>
                </div>
              ))}
            </div>
          </Token.wrapper>
        </Token.main>
      </ContainerStyled>
    </Section>
  );
}
