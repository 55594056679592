import { Main } from "./styles";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

const AcceptCookie = () => {
  return (
    <Main>
      <CookieConsent
        location="bottom"
        buttonText="Accept Cookies!"
        cookieName="acceptNOW"
        style={{
          background: "#000000c2",
        }}
        buttonStyle={{
          color: "#ffff",
          fontSize: "13px",
          background: "#8815ffb5",
        }}
        expires={30}
      >
        {" "}
        This website uses cookies to enhance the user experience.
        <Link
          style={{ marginLeft: "10px", color: "#ff00b8" }}
          to="/auth/policy"
        >
          Cookies Policy
        </Link>
        {/* <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
      </CookieConsent>
    </Main>
  );
};

export default AcceptCookie;
