import bg from "assets/img/404.jpg";
import styled from "styled-components";

export const Wrapper = {
  Main: styled.div`
    background: url(${bg});
    width: 100vw;
    height: 100vh;
    background-position: center;
  `,
  Container: styled.div`
    padding-top: 150px;
    // height: 400px;
    display: flex;
    align-items: center;
  `,
  Globe: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
    > img {
      width: 20%;
      height: 20%;
    }
    .globe {
      width: 25%;
      height: 25%;
      border-radius: 50%;
      box-shadow: 0px -1px 48px 7px rgb(255 244 244 / 49%);
      animation-name: rotate;
      animation-duration: 6s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;

      @keyframes rotate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
  `,
  Text: styled.div`
    text-align: center;
    > h1 {
      color: white;
    }
    > p {
      color: #ffffff73;
      font-size: 13px;
    }
    > button {
      color: white;
      padding: 10px 20px;
      border: 1px solid #4800ffed;
      border-radius: 30px;
      font-weight: bold;
      margin-top: 20px;
      box-shadow: 0px -1px 8px 7px rgb(255 244 244 / 9%);

      &:hover {
        transform: scale(1.05);
        transition: 0.7s;
        box-shadow: 0px -1px 8px 7px rgb(255 244 244 / 29%);
      }
    }
  `,
};
