import styled from "styled-components";

export const Title = styled.div`
  color: white;
  text-align: center;
  padding: 100px 0;
  font-size: 48px;
  font-weight: bold;
`;

export const Item = {
  Main: styled.div``,
};

export const Wrapper = {
  Main: styled.div`
    padding-top: 100px;
  `,
  Line: styled.div`
    border-radius: 30px;
    height: 5px;
    width: 70%;
    margin: auto;
    background: linear-gradient(
      115.36deg,
      rgba(244, 0, 116, 0.4) 33.92%,
      rgba(37, 19, 81, 0.4) 99.86%
    );
  `,
  Text: styled.div`
    text-align: center;
    font-size: 70px;
    font-weight: bold;
    color: white;
    text-shadow: 2px 2px #ff0000;
    @media (max-width: 769px) {
      font-size: 50px;
    }
    @media (max-width: 540px) {
      font-size: 35px;
    }
  `,
  Date: styled.div`
    color: #ffffffad;
    text-align: center;
    margin-top: -25px;
    font-family: "Roboto";
    font-weight: 500;
    @media (max-width: 1000px) {
      margin-top: 0px;
    }
  `,
  Button: styled.button`
    display: flex;
    margin: 30px auto;
    padding: 10px 50px;
    border-radius: 20px;
    color: white;
    font-weight: bold;
    background: linear-gradient(180deg, #e80274 0%, #7d0d6a 100%);
    font-family: "IBM Plex Sans";
    box-shadow: -1px -1px 15px 7px rgb(255 244 244 / 14%);
  `,
};
