import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleDeletaAccount } from "redux/slices/airdrop";
import { GetUser } from "utits/auth";

const ModalDeleteAccount = ({ setOpen, open }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.airdrop);

  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = async (data) => {
    try {
      dispatch(
        handleDeletaAccount({
          id: GetUser()?.id || 1,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Account Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete your account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            loading={isLoading}
            onClick={() => onSubmit()}
          >
            Agree
          </LoadingButton>
          <Button
            variant="outlined"
            color="error"
            onClick={handleClose}
            autoFocus
          >
            Disagree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ModalDeleteAccount;
