import { themes } from "common/styles/theme";
import { ContainerStyleds } from "components/styles/Layout.Styled";
import styled from "styled-components";

export const Section = styled.section`
  background: #08021c;
  height: 100%;
  width: 100vw;
  padding: 100px 0;
  position: relative;
  z-index: 2;
  @media (max-width: 991px) {
    padding: 50px 0 0 0;
    height: 100%;
  }
`;

export const ContainerStyled = styled(ContainerStyleds)`
  flex-direction: column;
  gap: 40px;

  > img {
    position: absolute;
    left: 0;
    z-index: 0;
  }
  > div {
    text-align: center;
    margin-bottom: 50px;
    z-index: 1;
    > h1 {
      color: white;
      font-weight: 700;
      font-size: 32px;
      > strong {
        font-weight: 700;
        font-size: 32px;
        color: #f40074;
      }
    }
  }
  @media (max-width: 991px) {
    gap: 20px;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  gap: 40px;

  @media (max-width: 991px) {
    display: none;
  }
`;

export const Item = styled.div`
  color: #fff;
  flex: 1;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 5px;
  box-shadow: 0px 10px 28px rgba(0, 0, 0, 0.12);
  background: linear-gradient(
    115.36deg,
    rgba(80, 45, 159, 0.4) 33.92%,
    rgba(8, 2, 28, 0) 99.86%
  );
  border: 2px solid rgba(106, 49, 129, 0.2);
  border-radius: 18px;
  max-width: 350px;
  max-height: 302px;
  min-height: 300px;
  width: 100%;
  &:nth-child(odd) {
    margin-top: -50px;
  }
  > div {
    &:first-child {
      cursor: pointer;
      margin-top: -70px;
      ${themes.mixin.flexCenter()};
    }
  }
  @media (max-width: 991px) {
    width: 100%;
    margin: auto;
  }
`;
export const Text = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: ${(props) => (props.w == true ? "420px" : "")};
  width: ${(props) => (props.w == true ? "100%" : "")};
  background: linear-gradient(
    115.36deg,
    rgba(244, 0, 116, 0.4) 33.92%,
    rgba(37, 19, 81, 0.4) 99.86%
  );
  border: 2px solid rgba(106, 49, 129, 0.2);
  border-radius: 18px;
  width: 100%;
  min-height: 140px;
  max-height: 140px;
  padding: 0 20px 0px 20px;

  @media (max-width: 991px) {
    max-width: 500px;
    padding: 5px 0;
  }
`;
export const TextItem = styled.p`
  margin-bottom: 0;
  width: 100%;
  padding: ${(props) => (props.pd == true ? "5px 0" : "10px 0")};
  font-family: "IBM Plex Sans";
  font-weight: 700;
  font-size: 24px;
  color: #f40074;
`;

export const Des = styled.p`
  color: white;
  max-width: 100%;
  overflow-y: scroll;
`;

export const Content2 = styled.div`
  display: none;
  width: 100%;
  gap: 40px;
  margin-top: 40px;
  @media (max-width: 991px) {
    display: block;
  }
`;
