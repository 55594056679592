import { createSlice } from "@reduxjs/toolkit";
import CreateApi from "api/CreateApi";
import { MessageToastError, MessageToastSuccess } from "components/Message";

const initialState = {
  isLoading: false,
  airdropRes: null,
};

export const handleCleanup = (body) => {
  return async (dispatch) => {
    dispatch(GetLoading(true));
    try {
      let res = await CreateApi.CreateApiSS(
        "public/account/account-cleanup",
        body
      );
      dispatch(GetDeposit(res?.data?.data));
      if (res?.data.status === true) {
        MessageToastSuccess(res?.data?.message);
        dispatch(GetLoading(false));
        return;
      } else {
        MessageToastError(res?.data?.message);
        dispatch(GetLoading(false));
        return;
      }
    } catch (error) {}
    dispatch(GetLoading(false));
  };
};

export const handleDeletaAccount = (body) => {
  return async (dispatch) => {
    dispatch(GetLoading(true));
    try {
      let res = await CreateApi.CreateApiSS("public/account-deletion", body);
      dispatch(GetDeposit(res?.data?.data));
      if (res?.data.status === true) {
        MessageToastSuccess(res?.data?.message);
        dispatch(GetLoading(false));
        return;
      } else {
        MessageToastError(res?.data?.message);
        dispatch(GetLoading(false));
        return;
      }
    } catch (error) {}
    dispatch(GetLoading(false));
  };
};

export const handleSendAirdrop = (body, reset) => {
  return async (dispatch) => {
    dispatch(GetLoading(true));
    try {
      let res = await CreateApi.CreateApiSS(
        "public/account/save-airdrop",
        body
      );
      dispatch(GetDeposit(res?.data?.data));
      if (res?.data.status === true) {
        CreateApi.CreateApiSSS("public/user/userinfo", true);
        MessageToastSuccess(res?.data?.message);
        reset({
          facebook: "",
          instagram: "",
          youtube: "",
          telegram: "",
          linkedin: "",
          reddit: "",
          discord: "",
          tiktok: "",
          twitter: "",
        });
        dispatch(GetLoading(false));
        return;
      } else {
        MessageToastError(res?.data?.message);
        dispatch(GetLoading(false));
        return;
      }
    } catch (error) {}
    dispatch(GetLoading(false));
  };
};

const airdropSlice = createSlice({
  name: "airdrop",
  initialState,
  reducers: {
    GetLoading(state, action) {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
    GetDeposit(state, action) {
      return {
        ...state,
        airdropRes: action.payload,
      };
    },
  },
});

const { reducer, actions } = airdropSlice;
export const { GetLoading, GetDeposit } = actions;
export default reducer;
