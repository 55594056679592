import { themes } from "common/styles/theme";
import { Container } from "react-bootstrap";
import styled from "styled-components";

export const Section = styled.section`
  background: #08021c;
  height: 100%;
  width: 100vw;
  position: relative;
  z-index: 1;
  padding: 40px 0;

  @media (max-width: 1000px) {
    background-size: cover;
    height: 100%;
    min-height: 500px;
    ${themes.mixin.flexCenter()};
  }
  @media (max-width: 600px) {
    padding: 0;
  }
`;
export const ContainerStyled = styled(Container)`
  width: 100%;
  height: 100%;
  position: relative;
  > img {
    position: absolute;
    top: 0;
    z-index: -1;
  }
  > div {
    z-index: 1;
  }
  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const Wrapper = {
  main: styled.div`
    > h1 {
      margin: 0;
      color: white;
      font-weight: 700;
      font-size: 32px;
      text-align: center;
      > strong {
        font-weight: 700;
        font-size: 32px;
        color: #f40074;
      }
    }
    > div {
      text-align: center;
      position: relative;
    }
  `,
  Video: styled.div`
    height: 600px;
    @media (max-width: 500px) {
      height: 400px;
    }
  `,
};

export const Token = {
  main: styled.div`
    ${themes.mixin.flexCenter()};
    margin-top: 50px;
  `,
  wrapper: styled.div`
    max-width: 400px;
    width: 100%;
    background: linear-gradient(
      115.36deg,
      rgba(80, 45, 159, 0.4) 33.92%,
      rgba(8, 2, 28, 0) 99.86%
    );
    border: 2px solid rgba(106, 49, 129, 0.2);
    border-radius: 18px;
    padding: 20px 50px;
    > h1 {
      color: #fcdae1;
      font-size: 32px;
      text-align: center;
    }
    color: white;
    > div {
      display: flex;
      flex-direction: column;
      gap: 10px;
      > h1 {
        color: #fcdae1;
        font-size: 28px;
        text-align: center;
        @media (max-width: 500px) {
          font-size: 18px;
        }
      }
      > div {
        display: flex;
        justify-content: space-between;
        @media (max-width: 500px) {
          font-size: 12px;
        }
      }
    }
  `,
};
