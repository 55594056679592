import styled from "styled-components";

export const Title = styled.div`
  color: white;
  font-size: 40px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;
export const Des = styled.p`
  color: #f40074;
  font-size: 18px;
  text-align: center;
`;
export const Progress = {
  Item: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  Main: styled.div`
    display: flex;
    gap: 50px;
    padding: 30px 0;
    > p {
      color: white;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 20px;
    }
  `,
  Done: styled.div`
    background: linear-gradient(270deg, #f40074 1.69%, #502d9f 97.75%);
    height: 35px;
    border-radius: 20px;
  `,
  Mana: styled.div`
    height: 35px;
    width: 100%;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 20px;
  `,
  Text: styled.div`
    display: flex;
    color: #d0c0c0;
    justify-content: space-between;
    margin-top: 10px;
  `,
};
