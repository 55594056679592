import CastConnectedIcon from "@mui/icons-material/CastConnected";
import HistoryIcon from "@mui/icons-material/History";
import StoreIcon from "@mui/icons-material/Store";
import SVG from "components/SVG";
import Airdrop from "pages/Airdrop";
import History from "pages/History";
import MyNFT from "pages/MyNFT";
import Promotion from "pages/Promotion";
import { lazy } from "react";
import { PATH } from "routes/PATH";

// const Home = lazy(() => import("pages/Home"));

// const Kyc = lazy(() => {
//   return new Promise(res => {
//     setTimeout(() => {
//       res(import("pages/Kyc"));
//     }, 2500)
//   });
// });

const Profile = lazy(() => {
  return new Promise((res) => {
    setTimeout(() => {
      res(import("pages/Profile"));
    }, 2500);
  });
});

const ViewToEarn = lazy(() => {
  return new Promise((res) => {
    setTimeout(() => {
      res(import("pages/ViewToEarn"));
    }, 2500);
  });
});

const Referral = lazy(() => {
  return new Promise((res) => {
    setTimeout(() => {
      res(import("pages/Referral"));
    }, 2500);
  });
});

const Farming = lazy(() => {
  return new Promise((res) => {
    setTimeout(() => {
      res(import("pages/Farming"));
    }, 2500);
  });
});

const Account = lazy(() => {
  return new Promise((res) => {
    setTimeout(() => {
      res(import("pages/Accounts"));
    }, 2500);
  });
});

const Wallet = lazy(() => {
  return new Promise((res) => {
    setTimeout(() => {
      res(import("pages/Wallet"));
    }, 2500);
  });
});

const Exchange = lazy(() => {
  return new Promise((res) => {
    setTimeout(() => {
      res(import("pages/Exchange"));
    }, 2500);
  });
});

// const BuyIco = lazy(() => import("pages/BuyIco"));

// const Exchange = lazy(() => import("pages/Exchange"));
// const Farm = lazy(() => import("pages/Farm"));
// const GameFi = lazy(() => import("pages/GameFi"));
// const Referral = lazy(() => import("pages/Referral"));
// const Staking = lazy(() => import("pages/Staking"));
// const Account = lazy(() => import("pages/Account"));
// const Wallet = lazy(() => import("pages/Wallet"));

export const dashboard = [
  // {
  //   name: "Home",
  //   path: PATH.HOME,
  //   isPrivate: false,
  //   component: <Home />,
  //   icon: <SVG name="drawer/home" />,
  // },
  // {
  //   name: JSON.parse(localStorage.getItem('user')).username,
  //   path: PATH.DASHBOARD.PROFILE,
  //   isPrivate: false,
  //   component: <Profile />,
  //   icon: <SVG name="drawer/home" />,
  // },

  {
    name: "Account",
    path: PATH.DASHBOARD.ACCOUNT,
    isPrivate: false,
    component: <Account />,
    icon: <SVG name="drawer/account" />,
  },
  {
    name: "Engagement",
    path: PATH.DASHBOARD.AIRDROP,
    isPrivate: false,
    component: <Airdrop />,
    icon: <CastConnectedIcon fontSize="small" sx={{ color: "#a100a5" }} />,
  },
  {
    name: "Wallet",
    path: PATH.DASHBOARD.WALLET,
    isPrivate: false,
    component: <Wallet />,
    children: [
      {
        name: "Deposit",
        title: "/wallet",
        link: "wallet",
      },
      {
        name: "Withdraw",
        title: "Withdraw",
        link: "wallet",
      },
      {
        name: "Transfer",
        title: "Transfer",
        link: "wallet",
      },
    ],
    icon: <SVG name="drawer/wallet" />,
  },
  // {
  //   name: "Kyc",
  //   path: PATH.DASHBOARD.KYC,
  //   isPrivate: false,
  //   component: <Kyc />,
  //   icon: <SVG name="svg-kyc" />,
  // },
  {
    name: "Profile",
    path: PATH.DASHBOARD.PROFILE,
    isPrivate: false,
    component: <Profile />,
    children: [
      {
        name: "2FA",
        title: "/profile",
        link: "profile",
      },
      {
        name: "Change Password",
        title: "Change Password",
        link: "profile",
      },
      {
        name: "KYC",
        title: "KYC",
        link: "profile",
      },
      {
        name: "Verify Account",
        title: "Verify Account",
        link: "profile",
      },
      {
        name: "Data Deletion",
        title: "Data Deletion",
        link: PATH.DASHBOARD.DATA_DELECTION,
      },
    ],
    icon: <SVG name="menu-profile" style={{ width: "18px" }} />,
  },

  // {
  //   name: "Profile",
  //   path: PATH.PROFILE,
  //   isPrivate: false,
  //   component: <Profile />,
  //   icon: <SVG name="drawer/staking" />,
  // },

  // {
  //   name: "Buy Ico",
  //   path: PATH.BUYICO,
  //   isPrivate: false,
  //   component: <BuyIco />,
  //   icon: <SVG name="drawer/buyico" />,
  // },

  {
    name: "Swap",
    path: PATH.DASHBOARD.SWAP,
    isPrivate: false,
    component: <Exchange />,

    icon: <SVG name="drawer/exchange" />,
  },
  {
    name: "Buy NFT",
    path: PATH.DASHBOARD.BUY_NFT,
    isPrivate: false,
    component: <Farming />,
    children: [
      {
        name: "Real Estate",
        title: "/buy-nft",
        link: "buy-nft",
      },
      {
        name: "Virtual Machine",
        title: "VirtualMachine",
        link: "buy-nft",
      },
      {
        name: "NFT Card",
        title: "card",
        link: "buy-nft",
      },

      // {
      //   name: "History NFT card",
      //   title: "History-NFT",
      //   link: "buy-nft",
      // },
    ],
    icon: <SVG name="drawer/staking" />,
  },
  {
    name: "My Assets",
    path: PATH.DASHBOARD.MY_NFT,
    isPrivate: false,
    component: <MyNFT />,
    children: [
      {
        name: "Real Estate",
        title: "/my-nft",
        link: "my-nft",
      },
      {
        name: "Virtual Machine",
        title: "VirtualMachine",
        link: "my-nft",
      },
      {
        name: "NFT Card",
        title: "card",
        link: "my-nft",
      },
    ],
    icon: <StoreIcon style={{ color: "#a100a5" }} />,
  },

  // {
  //   name: "Farm",
  //   path: PATH.FARM,
  //   isPrivate: false,
  //   component: <Farm />,
  //   icon: <SVG name="drawer/farm" />,
  // },
  // {
  //   name: "Game Fi",
  //   path: PATH.GAMEFI,
  //   isPrivate: false,
  //   component: <GameFi />,
  //   icon: <SVG name="drawer/gamefi" />,
  // },
  {
    name: "Referral",
    path: PATH.DASHBOARD.REFERRAL,
    isPrivate: false,
    component: <Referral />,
    icon: <SVG name="drawer/referral" />,
  },
  {
    name: "Earning",
    path: PATH.DASHBOARD.EARNING,
    isPrivate: false,
    component: <ViewToEarn />,
    children: [
      {
        name: "Normal",
        title: "/earning",
        link: "earning",
      },
      {
        name: "Card",
        title: "earning-card",
        link: "earning",
      },
    ],
    icon: <SVG name="drawer/mining" />,
  },
  {
    name: "Promotion",
    path: PATH.DASHBOARD.PROMOTION,
    isPrivate: false,
    component: <Promotion />,
    icon: <SVG name="drawer/promotion" />,
  },
  {
    name: "History",
    path: PATH.DASHBOARD.HISTORY,
    isPrivate: false,
    component: <History />,
    children: [
      {
        name: "Earning free",
        title: "earning",
        link: "history",
      },
      {
        name: "Earning Card",
        title: "/history",
        link: "history",
      },
      {
        name: "Commission Referral",
        title: "Commission Referral",
        link: "#",
        children: [
          {
            name: "Card",
            title: "referral-card",
            link: "history",
          },
          {
            name: "Location",
            title: "referral-location",
            link: "history",
          },
          {
            name: "Machine",
            title: "referral-machine",
            link: "history",
          },
        ],
      },
      {
        name: "Commission Earning",
        title: "Commission Earning",
        link: "#",
        children: [
          {
            name: "Card + Free",
            title: "commission-earning",
            link: "history",
          },
          {
            name: "Location",
            title: "commission-location",
            link: "history",
          },
          {
            name: "Machine",
            title: "commission-machine",
            link: "history",
          },
        ],
      },
    ],
    icon: <HistoryIcon style={{ color: "#a100a5" }} />,
  },

  // {
  //   name: "Support",
  //   path: PATH.DASHBOARD.SUPPORT,
  //   isPrivate: false,
  //   component: <Contact />,
  //   icon: <SVG name="drawer/promotion" />,
  // },
];
