import ico from "./ICO";
import referral from "./Referral";
import airdrop from "./airdrop";
import auth from "./auth";
import common from "./common";
import earningcard from "./earningcard";
import exchange from "./exchange";
import history from "./history";
import maps from "./maps";
import marketplace from "./marketplace";
import menu from "./menu";
import mining from "./mining";
import nft from "./nft";
import orther from "./orther";
import realtime from "./realTimeCoin";
import recaptchaF2A from "./recaptchaF2A";
import review from "./review";
import staking from "./staking";
import view from "./view";
import wallet from "./wallet";
import { combineReducers } from "redux";

const reducers = combineReducers({
  common,
  auth,
  exchange,
  recaptchaF2A,
  wallet,
  ico,
  staking,
  referral,
  view,
  orther,
  realtime,
  maps,
  review,
  mining,
  menu,
  nft,
  marketplace,
  earningcard,
  history,
  airdrop,
});
export default reducers;
