export const listHeader = [
  {
    title: "About",
    link: "/",
    children: [
      {
        name: "Ecosystem",
        link: "/",
      },
      {
        name: "Tokenomics",
        link: "/",
      },
      {
        name: "Roadmap",
        link: "/",
      },
      {
        name: "List Paper",
        link: "/",
      },
      {
        name: "Contact Us",
        link: "/",
      },
    ],
  },
  {
    title: "Marketplace",
    link: "/marketplace",
  },
  // {
  //   title: "Allocation ",
  // },
  // {
  //   title: "Tokenomics",
  // },
  // {
  //   title: "Roadmap",
  // },
  // {
  //   title: "Whitepaper",
  // },
  {
    title: "Maps",
    link: "/maps",
  },
];
export const ListMegaMenu = [
  {
    title: "ECOSYSTEM",
    link: "/",
  },
  {
    title: "TOKENOMICS",
    link: "/",
  },
  {
    title: "ROADMAP",
    link: "/",
  },
  {
    title: "LITE PAPER",
    link: "litepaper.pdf",
  },
  {
    title: "CONTACT US",
    link: "/auth/contact",
  },
];
export const ListMegaMenuChildren = [
  {
    title: "Swap",
    link: "/swap",
  },
  {
    title: "Real estate NFTs",
    link: "/maps",
  },
  {
    title: "Moblie Ad platform",
    link: "#",
  },
  {
    title: "Blockchain",
    link: "#",
  },
];

export const ListMegaMenuChildrenMb = [
  {
    title: "Ecosystem",
    link: "#",
  },
  {
    title: "Tokenomics",
    link: "/",
  },
  {
    title: "Roadmap",
    link: "/",
  },
  {
    title: "Lite Paper",
    link: "litepaper.pdf",
  },
  {
    title: "Contact us",
    link: "/auth/contact",
  },
];
