import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    gettokenrep: false,
    getvalue2fa: '',

};




const exchangeSlice = createSlice({
    name: "recaptchaF2A",
    initialState,
    reducers: {
        GetTokenRep(state, action) {
            return {
                ...state,
                gettokenrep: action.payload


            }
        },
        GetValue2FA(state, action) {
            return {
                ...state,
                getvalue2fa: action.payload


            }
        },
    },
});

const { reducer, actions } = exchangeSlice;
export const { GetTokenRep, GetValue2FA } = actions;
export default reducer;
