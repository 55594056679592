import { Section, Content, ContainerStyled, Items } from "./styles";
import loadingG from "assets/img/loadingg.gif";
import bg from "assets/svg/bg-NftItem.svg";
import EmptyData from "components/EmptyData";
import { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { handleGetMyVirtualMachine } from "redux/slices/nft";

const VirtualMachine = () => {
  const dispatch = useDispatch();
  const { listMyVirtualMachine, loadding } = useSelector((state) => state.nft);
  useEffect(() => {
    dispatch(handleGetMyVirtualMachine());
  }, []);
  return (
    <Section>
      <ContainerStyled>
        {loadding ? (
          <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
            <img src={loadingG} alt="" style={{ width: "15%" }} />
          </div>
        ) : (
          <>
            <Content>
              {listMyVirtualMachine?.total === 0 && <EmptyData />}
              {listMyVirtualMachine?.data?.map((e, i) => (
                <Items.Main key={i} data-aos="flip-left" data-aos-offset="30">
                  <Items.Name>{e?.name_package}</Items.Name>
                  <LazyLoadImage
                    alt={"img"}
                    effect="blur"
                    src={process.env.REACT_APP_PORT_IMAGE + e?.image_package}
                  />
                  <Items.Location>{e?.name_location}</Items.Location>
                  <Items.Bottom>
                    <Items.Date>{e?.date_start.substring(0, 10)}</Items.Date>
                    <Items.Profit>{e?.date_end.substring(0, 10)}</Items.Profit>
                  </Items.Bottom>
                  <Items.Bottom>
                    {/* <Items.Price>
                      ${formatMoney(String(fixedNumber(e.price_package, 2)))}
                    </Items.Price> */}
                    <Items.Status
                      className={
                        e?.status === "REJECTED" ? "REFUND" : e?.status
                      }
                    >
                      {/* {e?.active === 1 ? "ACTIVE" : "REFUND"} */}
                      {e?.status === "REJECTED" ? "REFUND" : e?.status}
                    </Items.Status>
                    {/* <Items.Buy onClick={() => hanldeBuyNFT(e?.id)}>
                      BUY NOW
                    </Items.Buy> */}
                  </Items.Bottom>
                </Items.Main>
              ))}
            </Content>
            <img src={bg} alt="" />
          </>
        )}
      </ContainerStyled>
    </Section>
  );
};
export default VirtualMachine;
