import * as mixin from "./mixin";

export const themes = {
    colors: {
        primary: "rgba(8, 2, 28, 0.8);",
        primaryOdd: "#0A6E7A",
        primaryActive: "#293931",
        primaryOpacity: "#007C91",
        white: "#FFFFFF",
        whiteDrak: "#F8F9FC",
        sky: "#00C2F2",
        gray: "#788EA7",
        violet: "#15073A",
        violetTable: "#3E0154",
        violetBtn: "#810084",
        violetFooter: "#450c41",

        linearBtn: "linear-gradient(#3A1c8A,#d749FB)",
        linearDark: "linear-gradient(#03122B,#033994)",
        linearCard: "#1A1059",
        linearCardFAQ: "linear-gradient(89.07deg, #4A137B 0.18%, #1E31A1 99.68%)",
        linerAccount: "linear-gradient(to right, #02A878 0%, #067780 100%)",
        linearTokennomics: "linear-gradient(261.91deg, #09A7D5 -0.86%, #032F8B 89.98%)",
        darkBlue: "#2D67CB",
        black: "#235462",
        blackActive: "#250036",

        lightBlack: "#152035",
        lightBlue: "#7693C0",
        blue: "#1D4EFA",
        yellow: "#D9E021",
        blueText: "#006D79",
        GreenText: "#4BBD99",
        button: "#00C398",
        textGray: "#535353",
        opacity: "#2d0140",
        orange: "#ED8203",
        green: "#1CC874",
        history: "#391d5c",
        wallet: "#810084",
        package: "#75ADE0",
        upkyc: "linear-gradient(180deg,rgba(95,90,90,.2),rgba(53,53,53,.2))",
        cancel: "#dd6e6e",
        pending: "#dfed03",
    },
    fz: {
        48: "48px",
        36: "36px",
        24: "24px",
        22: "22px",
        18: "18px",
        16: "16px",
        14: "14px",
        12: "12px",
    },
    fw: {
        bold: "700",
        semi: "600",
        medium: "500",
        regular: "400",
        light: "300",
    },
    shadow: {
        md: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
    timer: {
        300: "0.3s",
        500: "0.5s",
    },
    mixin,
};