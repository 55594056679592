import { themes } from "common/styles/theme";
import styled from "styled-components";

export const DashboardWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;
export const Drawers = {
  Container: styled.div`
    z-index: 11;
    background: linear-gradient(
      115.36deg,
      rgba(80, 45, 159, 0.4) 33.92%,
      rgba(8, 2, 28, 0) 99.86%
    );
    padding: 10px 15px;
    width: 400px;
    position: fixed;
    height: 100%;
    width: 250px;
    transition: all 0.3s;
    z-index: 2;

    @media (max-width: 1199px) {
      margin-left: ${(props) => (props.openDrawer == false ? "-250px" : 0)};
      background: linear-gradient(
        115.36deg,
        rgb(80 45 159) 33.92%,
        rgb(2 10 28) 99.86%
      );
    }
  `,
  LogoWrapper: styled.div`
    margin-bottom: 50px;
    margin-top: 10px;
  `,
  Children: styled.div`
    cursor: pointer;
    border-radius: 20px;
    ${({ active }) =>
      active &&
      `background: linear-gradient(115.36deg, rgba(244, 0, 116, 0.4) 33.92%, rgba(37, 19, 81, 0.4) 99.86%);
      `}
  `,

  ChildrenLink: styled.div`
    color: white;
    padding: 8px 20px;
    cursor: pointer;
    margin-left: 20px;
    font-size: 13px;
    ${({ active }) =>
      active && `font-weight:bold; text-shadow: 1px 2px #060505b5;`}
  `,

  ChildrenF2Link: styled.div`
    color: white;
    padding: 8px 20px;
    cursor: pointer;
    margin-left: 30px;
    font-size: 13px;
    ${({ active }) =>
      active && `font-weight:bold; text-shadow: 1px 2px #060505b5;`}
  `,

  Nav: styled.div`
    padding: 10px 15px;
    border-radius: 4px;
    ${themes.mixin.flexColCenter()};
    gap: 10px;
    transition: ${themes.timer[300]};
    // background-color: ${themes.colors.blackActive};
    background: none;
    border-radius: 20px;
    ${({ active }) =>
      active &&
      `
    background: none; 
    text-transform: uppercase;
    color: #b087eb;
    text-shadow: 1px 2px #060505b5;`}
    &:hover {
      transition: ${themes.timer[300]};
      background: linear-gradient(
        115.36deg,
        rgba(244, 0, 116, 0.4) 33.92%,
        rgba(37, 19, 81, 0.4) 99.86%
      );
      ${({ active }) =>
        active &&
        `
        background: none; 
        text-transform: uppercase;
        color: #b087eb;
        text-shadow: 1px 2px #060505b5;`}
    }
  `,
  IconWrapper: styled.div`
    width: 25px;
    height: 25px;
    ${themes.mixin.flexCenter()};
  `,
  Name: styled.h2`
    font-weight: ${themes.fw.regular};
    font-size: ${themes.fz[16]};
    color: ${themes.colors.white};
    ${({ active }) => active && `font-weight:bold`};
    margin: 0;
  `,
};
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 250px;
  width: 100%;
  z-index: 21;
  @media (max-width: 1199px) {
    margin-left: 0;
  }
`;

export const OverLay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: black;
  opacity: 0.5;
  z-index: 21;
  display: none;
  @media (max-width: 991px) {
    display: ${(props) => (props.openDrawer ? "block" : "none")};
  }
`;

export const LinkMain = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 40px);
  gap: 20px;
  padding-left: 15px;
  margin-top: 100px;
  > img {
    cursor: pointer;
  }
`;

export const Line = styled.div`
  width: 88%;
  height: 1px;
  background: white;
  margin-top: 5px;
`;
