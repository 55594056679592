import img from "assets/svg/empty-data.webp";

const EmptyData = () => {
  return (
    <>
      <div
        className="container"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={img} alt="" />
        <p
          style={{ color: "#ffffffa6", textAlign: "center", fontSize: "24px" }}
        >
          (Empty Data)
        </p>
      </div>
    </>
  );
};

export default EmptyData;
