import { createSlice } from "@reduxjs/toolkit";
import CreateApi from "api/CreateApi";
import { ErrorMessage, SuccessMessage } from "components/Message";
import { handleGetWallet } from "../wallet";

const initialState = {
    nameSwap: '',
    openModal: false,
    valueInput: '',
    getLoadding: false,
    getHistoryExchange: []
};



export const HandleModalCoin = (payload) => {

    return async (dispatch) => {
        dispatch(OpenModal(payload))

    }
}


export const handleExchange = (data) => {

    return async (dispatch) => {
        dispatch(GetLoading(true))

        try {
            let res = await CreateApi.CreateApiS("public/exchange/swap", data, true);
            if (res?.data?.status == true) {
                SuccessMessage('Exchange Success')
                dispatch(handleGetWallet())
                dispatch(handleHistoryExchange(1))
            }
            else {
                ErrorMessage(res?.data?.message)
            }

        } catch (error) {
            ErrorMessage('Please check again')
        }
        dispatch(GetLoading(false))
    };

}
export const handleExchangeUSDT = (data) => {

    return async (dispatch) => {
        dispatch(GetLoading(true))

        try {
            let res = await CreateApi.CreateApiS("public/exchange/usdt", data, true);
            SuccessMessage('Exchange Success')
            dispatch(handleGetWallet())
        } catch (error) {
            ErrorMessage('Please check again')
        }
        dispatch(GetLoading(false))
    };

}



export const handleHistoryExchange = (page) => {

    return async (dispatch) => {
        dispatch(GetLoading(true))

        try {
            let res = await CreateApi.CreateApiS("public/exchange/history",
                {
                    limit: 5,
                    page: page
                }
                , true);
            dispatch(GetHistoryExchange(res?.data))

        } catch (error) {
        }

        dispatch(GetLoading(false))
    };

}
const exchangeSlice = createSlice({
    name: "exchange",
    initialState,
    reducers: {
        OpenModal: (state, action) => {
            return {
                ...state,
                openModal: action.payload,


            }
        },
        ValueInput: (state, action) => {
            return {
                ...state,
                valueInput: action.payload,


            }
        },
        GetLoading: (state, action) => {
            return {
                ...state,
                getLoadding: action.payload,


            }
        },
        GetHistoryExchange: (state, action) => {
            return {
                ...state,
                getHistoryExchange: action.payload,


            }
        },
    },
});

const { reducer, actions } = exchangeSlice;
export const { OpenModal, ValueInput, GetLoading, GetHistoryExchange } = actions;
export default reducer;
