import { themes } from "common/styles/theme";
import styled from "styled-components";

export const Wrapper = styled.section`
  box-shadow: 0px 10px 28px rgba(0, 0, 0, 0.12);
  padding: 15px;

  > div {
    overflow: auto;
  }
`;

export const Historys = {
  main: styled.div`
    min-width: 750px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.16);
    border-radius: 18px;
  `,

  price: styled.div`
    strong {
      color: ${themes.colors.orange};
    }
  `,
  context: styled.div`
    color: ${themes.colors.white};
  `,
  contextTitle: styled.div``,
  itemTitle: styled.div`
    ${themes.mixin.flexColCenter()};
    padding: 15px;
    text-align: center;
    background: linear-gradient(
      90deg,
      #251351 0%,
      rgba(20, 9, 49, 0.31) 98.95%
    );
    box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.04);
    font-size: 14px;
  `,
  item: styled.div`
    display: flex;
    padding: 15px 10px;
    text-align: center;
    font-size: 14px;
    border-bottom: 1px solid #756b8e;
    > div {
      ${themes.mixin.flexCenter()};
      flex-direction: column;
      > strong {
        color: ${themes.colors.orange};
      }
    }
  `,
};
