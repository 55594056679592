import { createSlice } from "@reduxjs/toolkit";
import CreateApi from "api/CreateApi";
import { ErrorMessage, SuccessMessage } from "components/Message";

const initialState = {
    menuDrawer: localStorage.getItem("menu") || '',
};





const menuSlice = createSlice({
    name: "MenuDrawer",
    initialState,
    reducers: {
        MenuDrawer: (state, action) => {
            localStorage.setItem('menu', action.payload)
            return {
                ...state,
                menuDrawer: action.payload,
            };
        },
       

    },
});

const { reducer, actions } = menuSlice;
export const {
    MenuDrawer,
} = actions;
export default reducer;