import { createSlice } from "@reduxjs/toolkit";
import CreateApi from "api/CreateApi";
import { MessageToastError } from "components/Message";

const initialState = {
  loadding: false,
  historyProfit: [],
  historyProfitOnProfit: [],
  historyEarning: [],
  historyComissionEarning: [],
  historyOwnerLocation: [],
  historyOwnerPackage: [],
  historyReferralMachine: [],
  historyReferralLocation: [],
};
export const handleGetHistoryReferralMachine = (page) => {
  return async (dispatch) => {
    try {
      dispatch(Loadding(true));
      let res = await CreateApi.CreateApiSSS(
        `public/minning/history-comission-minning-owner-package?limit=10&page=${page}`,
        true
      );
      if (res?.data?.status === true) {
        dispatch(HistoryReferralMachine(res?.data?.data));
        dispatch(Loadding(false));
      } else {
        MessageToastError("Error history");
        dispatch(Loadding(false));
      }
    } catch (error) {
      dispatch(Loadding(false));
    }
  };
};
export const handleGetHistoryReferralLocation = (page) => {
  return async (dispatch) => {
    try {
      dispatch(Loadding(true));
      let res = await CreateApi.CreateApiSSS(
        `public/minning/history-comission-minning-owner-location?limit=10&page=${page}`,
        true
      );
      if (res?.data?.status === true) {
        dispatch(HistoryReferralLocation(res?.data?.data));
        dispatch(Loadding(false));
      } else {
        MessageToastError("Error history");
        dispatch(Loadding(false));
      }
    } catch (error) {
      dispatch(Loadding(false));
    }
  };
};

export const handleGetHistoryProfit = (page) => {
  return async (dispatch) => {
    try {
      dispatch(Loadding(true));
      let res = await CreateApi.CreateApiSSS(
        `public/card/history-receive-card?limit=10&page=${page}`,
        true
      );
      if (res?.data?.status === true) {
        dispatch(HistoryProfit(res?.data?.data));
        dispatch(Loadding(false));
      } else {
        MessageToastError("Error history");
        dispatch(Loadding(false));
      }
    } catch (error) {
      dispatch(Loadding(false));
    }
  };
};

export const handleGetHistoryProfitOnProfit = (page) => {
  return async (dispatch) => {
    try {
      dispatch(Loadding(true));
      let res = await CreateApi.CreateApiSSS(
        `public/card/history-comisson-card?limit=10&page=${page}`,
        true
      );
      if (res?.data?.status === true) {
        dispatch(HistoryProfitOnProfit(res?.data?.data));
        dispatch(Loadding(false));
      } else {
        MessageToastError("Error history");
        dispatch(Loadding(false));
      }
    } catch (error) {
      dispatch(Loadding(false));
    }
  };
};

export const handleGetHistoryEarning = (page) => {
  return async (dispatch) => {
    try {
      dispatch(Loadding(true));
      let res = await CreateApi.CreateApiSSS(
        `public/minning/history-minning?limit=10&page=${page}`,
        true
      );
      if (res?.data?.status === true) {
        dispatch(HistoryEarning(res?.data?.data));
        dispatch(Loadding(false));
      } else {
        MessageToastError("Error history");
        dispatch(Loadding(false));
      }
    } catch (error) {
      dispatch(Loadding(false));
    }
  };
};

export const handleGetHistoryComissionEarning = (page) => {
  return async (dispatch) => {
    try {
      dispatch(Loadding(true));
      let res = await CreateApi.CreateApiSSS(
        `public/minning/history-comission-minning?limit=10&page=${page}`,
        true
      );
      if (res?.data?.status === true) {
        dispatch(HistoryComissionEarning(res?.data?.data));
        dispatch(Loadding(false));
      } else {
        MessageToastError("Error history");
        dispatch(Loadding(false));
      }
    } catch (error) {
      dispatch(Loadding(false));
    }
  };
};

export const handleGetHistoryOwnerLocation = (page) => {
  return async (dispatch) => {
    try {
      dispatch(Loadding(true));
      let res = await CreateApi.CreateApiSSS(
        `public/minning/history-comission-minning-owner-location?limit=10&page=${page}`,
        true
      );
      if (res?.data?.status === true) {
        dispatch(HistoryOwnerLocation(res?.data?.data));
        dispatch(Loadding(false));
      } else {
        MessageToastError("Error history");
        dispatch(Loadding(false));
      }
    } catch (error) {
      dispatch(Loadding(false));
    }
  };
};

export const handleGetHistoryOwnerPackage = (page) => {
  return async (dispatch) => {
    try {
      dispatch(Loadding(true));
      let res = await CreateApi.CreateApiSSS(
        `public/minning/history-comission-minning-owner-package?limit=10&page=${page}`,
        true
      );
      if (res?.data?.status === true) {
        dispatch(HistoryOwnerPackage(res?.data?.data));
        dispatch(Loadding(false));
      } else {
        MessageToastError("Error history");
        dispatch(Loadding(false));
      }
    } catch (error) {
      dispatch(Loadding(false));
    }
  };
};

const miningSlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    Loadding: (state, action) => {
      return {
        ...state,
        loadding: action.payload,
      };
    },
    HistoryProfit: (state, action) => {
      return {
        ...state,
        historyProfit: action.payload,
      };
    },
    HistoryProfitOnProfit: (state, action) => {
      return {
        ...state,
        historyProfitOnProfit: action.payload,
      };
    },
    HistoryEarning: (state, action) => {
      return {
        ...state,
        historyEarning: action.payload,
      };
    },
    HistoryComissionEarning: (state, action) => {
      return {
        ...state,
        historyComissionEarning: action.payload,
      };
    },
    HistoryOwnerLocation: (state, action) => {
      return {
        ...state,
        historyOwnerLocation: action.payload,
      };
    },
    HistoryOwnerPackage: (state, action) => {
      return {
        ...state,
        historyOwnerPackage: action.payload,
      };
    },
    HistoryReferralMachine: (state, action) => {
      return {
        ...state,
        historyReferralMachine: action.payload,
      };
    },
    HistoryReferralLocation: (state, action) => {
      return {
        ...state,
        historyReferralLocation: action.payload,
      };
    },
  },
});

const { reducer, actions } = miningSlice;
export const {
  HistoryProfit,
  Loadding,
  HistoryProfitOnProfit,
  HistoryEarning,
  HistoryComissionEarning,
  HistoryOwnerLocation,
  HistoryOwnerPackage,
  HistoryReferralMachine,
  HistoryReferralLocation,
} = actions;
export default reducer;
