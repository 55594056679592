import block1 from "assets/img/block/block1.png";
import block2 from "assets/img/block/block2.png";
import block3 from "assets/img/block/block3.png";
import block4 from "assets/img/block/block4.png";
import block5 from "assets/img/block/block5.png";
import block6 from "assets/img/block/block6.png";
import block7 from "assets/img/block/block7.png";

export const data = [
  {
    id: 1,
    icon: block1,
    price: 200,
    date: "November 2022",
    total: "1,000",
  },
  {
    id: 2,
    icon: block2,
    price: 300,
    date: "January 2023",
    total: "4,000",
  },
  {
    id: 3,
    icon: block3,
    price: 500,
    date: "March 2023",
    total: "5,000",
  },
  {
    id: 4,
    icon: block4,
    price: 800,
    date: " April 2023",
    total: "20,000",
  },
  {
    id: 5,
    icon: block5,
    price: 1200,
    date: "July 2023",
    total: "20,000",
  },
  {
    id: 6,
    icon: block6,
    price: 1600,
    date: "August 2023 ",
    total: "20,000",
  },
  {
    id: 7,
    icon: block7,
    price: 2000,
    date: "January 2024",
    total: "30,000",
  },
];
