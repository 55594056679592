import styled from "styled-components";

export const Main = styled.div`
  .CookieConsent {
    @media (max-width: 467px) {
      justify-content: space-evenly !important;
      text-align: center;
    }
  }
`;
