import { themes } from "common/styles/theme";
import styled from "styled-components";

export const NFTLocationStyles = {
  Main: styled.div`
    text-align: center;
    padding: 20px 0;
    // box-shadow: -1px -1px 15px 7px rgb(255 244 244 / 14%);
    background: #08021c;
    position: relative;
    max-width: 100vw;
    overflow: hidden;
  `,
  Title: styled.div`
    opacity: 0.8;
    color: #bb97ff;
    font-size: 36px;
    font-weight: lighter;
  `,
  Content: styled.div`
    opacity: 0.6;
    color: #bb97ff;
    font-size: 16px;
    font-weight: normal;
  `,
  Container: styled.div`
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 40px;
    justify-content: space-between;
    column-gap: 20px;
    justify-content: center;
    position: relative;
    z-index: 3;
  `,
};

export const Pagi = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const Wrapper = {
  main: styled.div`
    min-width: 270px;
    max-width: 270px;
    border: 1px solid #780d69;
    border-radius: 18px;
    box-shadow: -1px -1px 10px 7px rgb(255 244 244 / 3%);
  `,

  title: styled.div`
    color: white;
    ${themes.mixin.flexColCenter()};
    justify-content: space-between;
    padding: 10px;
    > h2 {
      color: #fcdae1;
      margin: 0;
    }
    > div {
      > p {
        font-size: 12px;
      }
    }
  `,

  Ratting: styled.div`
    display: flex;
    gap: 3px;
  `,

  content: styled.div`
    text-align: start;
    padding: 10px;
    > h3 {
      color: white;
      margin: 0;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 16px;
      font-weight: bold;
    }
    > span {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 12px;
      color: #ffffffa6;
    }
    color: white;
  `,
  Bottom: styled.div`
    display: flex;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
  `,
  Detail: styled.button`
    border: none;
    outline: none;

    cursor: pointer;
    color: white;
    background: linear-gradient(
      115.36deg,
      #502d9f 33.92%,
      rgba(8, 2, 28, 0.85) 99.86%
    );
    padding: 5px 10px;
    border-radius: 15px;
    font-weight: bold;
    width: 105px;
    &:hover {
      transform: scale(1.05);
      transition: 0.7s;
    }
  `,
  Buy: styled.button`
    border: none;
    outline: none;
    color: inherit;
    cursor: pointer;
    color: white;
    background: linear-gradient(90deg, #ff07dc 0%, #ec01740d 100%);
    padding: 5px 10px;
    border-radius: 15px;
    font-weight: bold;
    width: 105px;
    &:hover {
      transform: scale(1.05);
      transition: 0.7s;
    }
  `,
  Image: styled.div`
    min-width: 268px;
    max-width: 268px;
    min-height: 264px;
    max-height: 264px;
    > span {
      height: 264px;
      object-fit: cover;
      width: 100%;
      > img {
        width: 100%;
      }
    }
  `,
};
