import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Overlay } from "redux/slices/auth";

const box = {
  position: "fixed",
  width: "100vw",
  height: "100vh",
  background: "rgba(13, 35, 75, 0.8)",
  zIndex: "20",
  top: 0,
};
function OverlayContent(props) {
  const dispatch = useDispatch();
  const { overlay } = useSelector((state) => state.auth);

  useEffect(() => {
    if (overlay) document.querySelector("body").style = "overflow:hidden";
    else document.querySelector("body").style = "overflow:initial";
  }, [overlay]);
  return (
    <>{overlay && <Box sx={box} onClick={() => dispatch(Overlay(""))}></Box>}</>
  );
}

export default OverlayContent;
