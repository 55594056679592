import { themes } from "common/styles/theme";
import { theme } from "components/styles/Theme";
import { Container } from "react-bootstrap";
import styled from "styled-components";

export const Section = styled.section `
  background: #08021c;
  height: 100%;
  width: 100vw;
  position: relative;
  z-index: 1;
  padding: 50px 0;

  @media (max-width: 1000px) {
    background-size: cover;
    height: 100%;
    min-height: 500px;
    ${themes.mixin.flexCenter()};
  }
  @media (max-width: 600px) {
    padding: 0;
  }
`;
export const ContainerStyled = styled(Container)
`
  width: 100%;
  height: 100%;
  position: relative;
  > img {
    position: absolute;
    bottom: -20%;
    z-index: -1;
  }
  > div {
    z-index: 1;
  }
  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const Wrapper = {
    main: styled.div `
    > h1 {
      color: white;
      font-weight: 700;
      font-size: 32px;
      text-align: center;
      > strong {
        font-weight: 700;
        font-size: 32px;
        color: #f40074;
      }
    }
    > div {
      text-align: center;
      position: relative;
    }
  `,
};