import { Main } from "./styles";
import bg from "assets/svg/bg-Trusted.svg";

const PrivacyPolicy = () => {
  return (
    <div
      style={{
        position: "relative",
        maxWidth: "1200px",
        paddingLeft: "15px",
        paddingRight: "15px",
        margin: "0 auto",
      }}
    >
      <Main.Title>Privacy Policy</Main.Title>
      {/* <Main.Version>Version: 1.2 - Effective Date: 10/07/2022</Main.Version> */}
      <img
        src={bg}
        alt=""
        style={{
          position: "absolute",
          bottom: "0",
          width: "80%",
          right: "-45%",
        }}
      />
      <Main.ContentRight>
        <div
          style={{
            color: "#ffffff85",
            textAlign: "justify",
          }}
        >
          <p>
            Toearnnow and its affiliates include but are not limited to: owners,
            directors, investors, employees or other stakeholders. Depending on
            the context, "Toearnnow" may also refer to services, products,
            websites, content or other materials provided by Toearnnow.
          </p>
          <br />
          <p>
            Toearnnow and its affiliates are committed to protecting and
            respecting your privacy
          </p>
          <br />
          <p>
            "Personal Information" is information that personally identifies you
            or through which your identity can be reasonably identified. This
            may include your name, address, e-mail address and other contact
            details.
          </p>

          <p>The purpose of this Privacy Policy is to inform you of: </p>

          <ul style={{ padding: "20px", fontSize: "18px" }}>
            <li>
              The types of Personal Information we may collect about you and how
              it is used
            </li>
            <br />
            <li>
              use of information in relation to our IP Address and use of
              cookies
            </li>
            <br />
            <li>
              how your Personal Information may be disclosed to third parties
            </li>
            <br />
            <li>
              the ability to access, correct, update and delete your Personal
              Information
            </li>
            <br />
            <li>
              the security measures we put in place to prevent the loss, misuse
              or alteration of Personal Information under our control and to
              keep it private
            </li>
            <br />
          </ul>
        </div>
        <b>GATHERING AND USE OF PERSONAL INFORMATION</b>
        <div
          style={{
            padding: "20px",
            fontSize: "18px",
            color: "#ffffff85",
            textAlign: "justify",
          }}
        >
          <ul>
            <li>
              We may collect your personal information if you use the website,
              open an account or make any transactions on the website. The types
              of personal information we collect may include:
            </li>
            <br />
            <li>
              Your identity, including a high-quality photo of your identity
              card or passport
            </li>
            <br />
            <li>Your address, email address</li>
            <br />
            <li>Your bank details including account number</li>
            <br />
            <li>
              Your Industry, Your Utility Bill or a bank statement confirming
              your residential address
            </li>
            <br />
          </ul>
        </div>
        <b>PURPOSE OF USING PERSONAL INFORMATION</b>
        <div
          style={{
            padding: "20px",
            fontSize: "18px",
            color: "#ffffff85",
            textAlign: "justify",
          }}
        >
          <ul>
            <li>
              To personalize your experience (your information will help us
              better meet your individual needs)
            </li>
            <br />
            <li>
              To improve and analyze our website (we constantly strive to
              improve website services based on the feedback we receive from
              you)
            </li>
            <br />
            <li>To improve customer service</li>
            <br />
            <li>
              Verify your identity with our provider to comply with anti-money
              laundering policies
            </li>
            <br />
            <li>
              To process transactions. Your information will not be sold,
              exchanged, or given to any other company for any reason without
              your consent.
            </li>
            <br />
            <li>
              To send periodic emails. The email address you provide may be used
              to send information and updates regarding your order or request,
              and to be regularly updated with related promotions, products or
              services. to information,...
            </li>
            <br />
          </ul>
        </div>
        <b> OUR COOKIE POLICY</b>
        <div
          style={{
            padding: "20px",
            fontSize: "18px",
            color: "#ffffff85",
            textAlign: "justify",
          }}
        >
          <p>
            Once you agree to allow our website to use cookies, you also agree
            to use the data it collects regarding your online behavior (analyze
            web traffic, web pages you spend the most time on, and websites you
            visit).
          </p>
          <br />
          <p>
            The data we collect by using cookies is used to customize our
            website to your needs. After we use the data for statistical
            analysis, the data is completely removed from our systems.
          </p>
          <br />
          <p>
            Please note that cookies don't allow us to gain control of your
            computer in any way. They are strictly used to monitor which pages
            you find useful and which you do not so that we can provide a better
            experience for you.
          </p>
          <br />
          <p>
            If you want to disable cookies, you can do it by accessing the
            settings of your internet browser.
          </p>
          <a
            href="https://support.google.com/chrome/answer/95647?hl=en&co=GENIE.Platform=Desktop"
            target="_blank"
            style={{ color: "blue" }}
          >
            https://support.google.com/chrome/answer/95647?hl=en&co=GENIE.Platform=Desktop{" "}
          </a>{" "}
        </div>
        <b>LINK TO THIRD PARTY</b>
        <div
          style={{
            padding: "20px",
            fontSize: "18px",
            color: "#ffffff85",
            textAlign: "justify",
          }}
        >
          <p>
            From time to time, at our discretion, we may offer third party
            products or services on our website. Third-party websites will have
            separate and independent privacy policies. We encourage you to
            review all privacy policies, rules, terms and regulations, of each
            website you visit. However, we will seek to protect the integrity of
            the websites and welcome any feedback about them.
          </p>
          <br />
          <p>
            We do not control third party websites or any content contained
            therein, and we are not responsible for any of these third party
            websites, including without limitation, their content, policies and
            practices. , their promotions, products, services or actions or any
            damage, loss, failure or problems caused by, in connection with or
            arising out of such websites.
          </p>
        </div>
        <b>REPAIR/UPDATE/DELETE PERSONAL INFORMATION</b>{" "}
        <div
          style={{
            padding: "20px",
            fontSize: "18px",
            color: "#ffffff85",
            textAlign: "justify",
          }}
        >
          <p>
            You have the right to access your personal information and request
            correction, update and prevention of inaccuracies by emailing us at:
            ................ Toearnnow will act on your request only in the
            event that the data collected is inconsistent with the terms of use,
            legal and operating obligations.
          </p>
          <br />
          <p>
            Upon your written request, we will provide you with personal
            information relating to you that we hold
          </p>
        </div>
        <b>SECURITY</b>
        <div
          style={{
            padding: "20px",
            fontSize: "18px",
            color: "#ffffff85",
            textAlign: "justify",
          }}
        >
          <p>
            We take reasonable steps to protect your Personal Information from
            misuse, loss, unauthorised access, modification or disclosure,
            including implementing appropriate security measures. The security
            measures in place will, from time to time, be reviewed in line with
            legal and technical developments. However, we give no guarantee that
            such misuse, loss, unauthorised access, modification or disclosure
            will not occur.
          </p>
        </div>
        <b>RETENTION OF PERSONAL INFORMATION</b>
        <div
          style={{
            padding: "20px",
            fontSize: "18px",
            color: "#ffffff85",
            textAlign: "justify",
          }}
        >
          <p>
            We will only keep your Personal Information as we need it, in
            connection with the purposes described in this Privacy Policy and
            our legal and regulatory requirements. In general, Personal
            Information pertains to your Account for at least five years after
            your Account is closed by a user. Similarly, we generally retain
            information about Transactions on your Account for a period of five
            years from the date of the Transaction. Personal Information
            collected for other purposes will be removed in accordance with our
            policy from time to time.
          </p>
          <br />
          <p>
            We are authorized to provide information to victims and stakeholders
            in the event that your account is involved in fraudulent or illegal
            activities
          </p>
        </div>
        <b>CHANGES</b>
        <div
          style={{
            padding: "20px",
            fontSize: "18px",
            color: "#ffffff85",
            textAlign: "justify",
          }}
        >
          <p>
            Our policies, content, information, promotions, disclosures,
            disclaimers and features may be revised, modified, updated, and/or
            supplemented at any time and without prior notice at the sole and
            absolute discretion of the Company. If we change this Privacy
            Policy, we will take steps to notify all Users by a notice on our
            website and will post the amended Privacy Policy on the website.
          </p>
        </div>
        <b>SMS Opt-In Policy for Phone Number Verification</b>
        <div
          style={{
            padding: "20px",
            fontSize: "18px",
            color: "#ffffff85",
            textAlign: "justify",
          }}
        >
          <p>
            Thank you for choosing to use our platform. To ensure the security
            and integrity of your account, we require phone number verification
            as part of our account setup process. Please read this SMS Opt-In
            Policy carefully to understand how we handle your phone number and
            messaging.
          </p>
        </div>
        <b>Purpose of SMS Verification:</b>
        <div
          style={{
            padding: "20px",
            fontSize: "18px",
            color: "#ffffff85",
            textAlign: "justify",
          }}
        >
          <p>
            To enhance the security of your account and protect your personal
            information, we use SMS verification to confirm that the phone
            number provided during the registration process belongs to you.
          </p>
        </div>
        <b>Opting In:</b>
        <div
          style={{
            padding: "20px",
            fontSize: "18px",
            color: "#ffffff85",
            textAlign: "justify",
          }}
        >
          <p>
            By signing up for our platform, you agree to receive SMS messages
            for phone number verification. These messages will be sent to the
            phone number you provided during registration.
          </p>
        </div>
        <b>Frequency of Messages:</b>
        <div
          style={{
            padding: "20px",
            fontSize: "18px",
            color: "#ffffff85",
            textAlign: "justify",
          }}
        >
          <p>
            We will send an SMS containing a verification code to your
            registered phone number during the signup and login processes.
            Additional messages may be sent for account recovery or
            security-related notifications.
          </p>
        </div>
        <b>No Solicitation of Sensitive Data:</b>
        <div
          style={{
            padding: "20px",
            fontSize: "18px",
            color: "#ffffff85",
            textAlign: "justify",
          }}
        >
          <p>
            We will never send you a message requesting your sensitive data,
            including your password or seed phrase. If you receive any such
            message, please do not respond to it and report it immediately to
            our support team.
          </p>
        </div>
        <b>Message Content:</b>
        <div
          style={{
            padding: "20px",
            fontSize: "18px",
            color: "#ffffff85",
            textAlign: "justify",
          }}
        >
          <p>
            Our SMS messages will only contain verification codes or
            account-related information, such as login alerts or password reset
            instructions. We will not include any links or requests for personal
            or financial information in our SMS messages.
          </p>
        </div>
        <b>Data Privacy:</b>
        <div
          style={{
            padding: "20px",
            fontSize: "18px",
            color: "#ffffff85",
            textAlign: "justify",
          }}
        >
          <p>
            We take your privacy seriously. Your phone number and any
            information related to it will be handled per our Privacy Policy. We
            will not share your phone number with third parties without your
            consent.
          </p>
        </div>
        <b>Account Security:</b>
        <div
          style={{
            padding: "20px",
            fontSize: "18px",
            color: "#ffffff85",
            textAlign: "justify",
          }}
        >
          <p>
            You are responsible for keeping your account and phone number
            secure. Do not share your verification code or other account
            information with anyone. If you suspect unauthorized access to your
            account, contact our support team immediately.
          </p>
        </div>
        <b>Opting Out:</b>
        <div
          style={{
            padding: "20px",
            fontSize: "18px",
            color: "#ffffff85",
            textAlign: "justify",
          }}
        >
          <p>
            If you wish to opt out of SMS messages for phone number
            verification, please note that you may not be able to use our
            platform. Phone number verification is essential for security
            purposes.
          </p>
        </div>
        <b>Contact:</b>
        <div
          style={{
            padding: "20px",
            fontSize: "18px",
            color: "#ffffff85",
            textAlign: "justify",
          }}
        >
          <p>
            If you have any questions or concerns regarding this SMS Opt-In
            Policy, please contact our support team through the provided
            channels.
            <br />
            By continuing to use our platform, you acknowledge that you have
            read, understood, and agreed to this SMS Opt-In Policy. Your
            security and privacy are of utmost importance to us, and we
            appreciate your trust in our services.
          </p>
        </div>
      </Main.ContentRight>
      <img
        src={bg}
        alt=""
        style={{
          position: "absolute",
          top: "0",
          left: "-65%",
          width: "100%",
          overflow: "hidden",
        }}
      />
    </div>
  );
};
export default PrivacyPolicy;
