import { Box } from "@mui/material";
import { themes } from "common/styles/theme";
import { theme } from "components/styles/Theme";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

export const Section = styled.section`
  background: #08021c;
  height: 100%;
  width: 100vw;
  position: relative;
  margin: 50px 0;

  @media (max-width: 1000px) {
    padding: 50px;
    height: 100%;
  }
  @media (max-width: 600px) {
    padding: 0;
  }
`;
export const ContainerStyled = styled(Container)`
  width: 100%;
  height: 100%;
  position: relative;
  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    min-height: 500px;
  }
`;
export const RowStyled = styled(Row)`
  background: linear-gradient(
    115.36deg,
    rgba(80, 45, 159, 0.4) 33.92%,
    rgba(8, 2, 28, 0) 99.86%
  );
  border: 2px solid rgba(106, 49, 129, 0.2);
  border-radius: 18px;
  ${themes.mixin.flexCenter()};
  height: 100%;
  width: 100%;
  @media (max-width: 991px) {
    flex-direction: column;

    gap: 20px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 40px;
  }
`;

export const ColStyled = styled(Col)`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  > img {
    width: 100%;
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
  }
  > div {
    ${themes.mixin.flexCenter()};
    gap: 8px;
    > h1 {
      margin: 0;
      color: white;
      font-size: 32px;
      @media (max-width: 500px) {
        font-size: 24px;
      }
    }
    > strong {
      font-size: 32px;
      color: red;
      @media (max-width: 500px) {
        font-size: 24px;
      }
    }
  }
  @media (max-width: 991px) {
    justify-content: end;
    align-items: center;
    > img {
      border-bottom-left-radius: 18px;
      border-bottom-right-radius: 18px;
    }
  }
`;
export const ColStyledRight = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Image = styled.img``;
export const Title = styled.div`
  > div {
    > h3 {
      color: white;
      font-family: "IBM Plex Sans";
      font-weight: 300;
      text-align: center;
      font-size: 24px;
      margin: 0;
    }
  }
`;

export const Download = {
  main: styled.div`
    width: 100%;
    ${themes.mixin.flexCenter()};
    position: relative;
    > img {
      position: absolute;
      right: 0;
      z-index: 0;
    }
  `,
  wrapper: styled.div`
    max-width: 600px;
    width: 100%;
    padding: 30px 0;
    z-index: 1;

    > h1 {
      color: white;
      font-weight: 700;
      font-size: 32px;
      > strong {
        font-weight: 700;
        font-size: 32px;
        color: #f40074;
      }
    }
    > div {
      > p {
        color: white;
        font-size: 18px;
      }
    }
  `,
};
