import { themes } from "common/styles/theme";
import { ContainerStyleds } from "components/styles/Layout.Styled";
import styled from "styled-components";

export const Section = styled.section `
  background: #08021c;
  height: 100%;
  width: 100vw;
  padding: 100px 0;
  z-index: 1;
  @media (max-width: 991px) {
    padding: 50px 0 0 0;
    height: 100%;
  }
`;

export const ContainerStyled = styled(ContainerStyleds)
`
  flex-direction: column;
  gap: 40px;
  z-index: 1;

  > img {
    position: absolute;
    z-index: -1;
  }
  > div {
    width: 100%;
    text-align: center;
    > h2 {
      color: white;
      font-size: 32px;
      font-weight: 700;
    }
    > p {
      color: white;
    }
  }
  @media (max-width: 991px) {
    gap: 20px;
  }
`;

export const Content = styled.div `
  display: flex;
  flex-wrap:wrap;
  justify-content: center;
  width: 100%;
  column-gap: 50px;
  row-gap: 20px;
  @media (max-width: 991px) {
    display: none;
  }
`;

export const Item = styled.div `
  cursor: pointer;
  color: #fff;
  height: 100%;
 ${themes.mixin.flexCenter()};
  flex-direction: column;
  gap: 10px;
  box-shadow: 0px 10px 28px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  max-width: 320px;
  width: 100%;
  margin-bottom: 20px;
  background: #140e25;

  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;

export const Image = styled.div `
  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  > img {
    -webkit-animation: rotating 2s linear infinite;
    max-width: 109px;
  }
`;

export const Text = styled.div `
  color: #536981;
  ${themes.mixin.flexCenter()};
  width: 100%;
  max-width: ${(props) => (props.w == true ? "420px" : "")};
  width: ${(props) => (props.w == true ? "100%" : "")};
  margin-top: ${(props) => (props.w == true ? "20px" : "")};
  padding-left: 10px;
  > div {
    width: 100%;
    max-width: 130px;
    text-align: start;
    color: white;
    padding: 10px;
  }
  > p {
    padding: 0;
    text-align: start;
  }

  @media (max-width: 991px) {
    width: 100%;
    max-width: 500px;
  }
`;
export const TextItem = styled.p `
  margin-bottom: 0;
  width: 100%;
  padding: ${(props) => (props.pd == true ? "5px 0" : "10px 0")};
  font-family: "IBM Plex Sans";
  font-weight: 700;
  color: #fcdae1;
  font-size: 20px;
`;
export const DesMain = styled.div `
  width: 100%;
  display: flex;
  justify-content: space-between;
  > div {
    font-size: 12px;
  }
`;
export const Des = styled.p `
  color: #536981;
  max-width: 300px;
`;

export const Content2 = styled.div `
  display: none;
  width: 100%;
  gap: 40px;
  @media (max-width: 991px) {
    display: block;
  }
`;

export const CountDown = {
    main: styled.div `
    background: rgba(255, 255, 255, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    margin-top: -35px;
    ${themes.mixin.flexCenter()};
    width: 100%;
    padding: 0 3px;
  `,
    item: styled.div `
    ${themes.mixin.flexCenter()};
    flex-direction: column;
    padding: 3px;

    > h1 {
      margin: 0;
      color: #ffdee3;
      font-size: 15px;
    }
    > p {
      color: #ffdee3;
      margin: 0;
      font-size: 8px;
    }
    > span {
      color: #ffdee3;
      font-size: 14px;
      margin-top: -15px;
    }
    @media (max-width: 500px) {
      padding: 3px;
      > h1 {
        font-size: 16px;
      }
    }
  `,
};

export const StyledButton = styled.div `
  justify-content: space-between;
  display: flex;
  width: 100%;
  > button {
    width: 105px;
    height: 30px;
    background: linear-gradient(90deg, #780d69 0%, #ec0174 100%);
    border-radius: 16.5px;
    font-weight: 700;
  }
`;

export const ItemFlex = styled.div `
  ${themes.mixin.flexCenter()};
  gap: 20px;
`;

export const Overlay = styled.div `
  position: absolute;
  height: 60px;
  width: 100%;
  background: #242130;
  bottom: 26px;
  opacity: 0.8;
  z-index: -1;
`;

export const Download = {
    main: styled.div `
  width: 100%;
  ${themes.mixin.flexCenter()};
  position:relative;
  >img{
    position: absolute;
    right: 0;
    z-index:0
  }
`,
    wrapper: styled.div `
  max-width: 600px;
  width:100%;
  padding:30px 0;
  z-index:1;

  background: linear-gradient(
    115.36deg,
    rgba(80, 45, 159, 0.4) 33.92%,
    rgba(8, 2, 28, 0) 99.86%
  );
  border: 2px solid rgba(106, 49, 129, 0.2);
  border-radius: 18px;
  > h1 {
    color: white;
    font-weight: 700;
    font-size: 32px;
    > strong {
      font-weight: 700;
      font-size: 32px;
      color: #f40074;
    }
  }
  > div {
    > p {
      color: white;
      font-size: 18px;
    }
  }
`,
};