import {
  ContainerStyled,
  RowStyled,
  Section,
  Title,
  ColStyled,
  StyledButton,
} from "./Banner.Styled";
import bgBanner from "assets/img/banner.webp";
import useProgressiveImage from "hooks/useProgressiveimage";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { animated } from "react-spring";
import Typical from "react-typical";

function Banner() {
  const navigate = useNavigate();
  const loaded = useProgressiveImage(bgBanner);

  return (
    <>
      <Section src={`${loaded}`}>
        <ContainerStyled>
          <RowStyled>
            <ColStyled md={12} lg={6} data-aos="fade-up" data-aos-offset="30">
              <Title>
                <animated.div>
                  <Typical
                    steps={["", 1000, "Claim 50 token NOW, It’s free!"]}
                    wrapper="h1"
                  />
                </animated.div>
              </Title>

              <StyledButton>
                <button onClick={() => navigate("/auth/login")}>
                  JOIN NOW
                </button>
              </StyledButton>
            </ColStyled>
          </RowStyled>
        </ContainerStyled>
      </Section>
    </>
  );
}

export default Banner;
