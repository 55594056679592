import { ErrorMessage, SuccessMessage } from "../../../components/Message";
import { Wrapper } from "./styles";
import Bg from "assets/img/background/bg-auth.png";
import axios from "axios";
import Footer from "pages/HomeMain/footer";
import Header from "pages/HomeMain/header";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const DeviceConfirm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");
  const DeviceID = queryParams.get("DeviceID");
  const token = queryParams.get("token");
  const userid = queryParams.get("userid");
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(
        // "https://apitest.toearnnow.com:9088/public/account/confirm-codedeviceweb",
        "https://apinewnew.toearnnow.com:9087/public/account/confirm-codedeviceweb",
        {
          params: {
            code,
            userid,
            DeviceID,
            token,
          },
        }
      )
      .then((response) => {
        if (response.data?.status === true) {
          SuccessMessage(response.data.message || "");
          setTimeout(() => {
            navigate("/login");
          }, 1000);
        } else {
          ErrorMessage(response.data.message || "");
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      })
      .catch((error) => {
        ErrorMessage(error.response.data.message || "");
      });
  }, []);

  return (
    <>
      <Header />
      <Wrapper.section
        style={{ background: `url(${Bg})`, backgroundSize: "cover" }}
      ></Wrapper.section>
      <Footer />
    </>
  );
};
export default DeviceConfirm;
