import { createSlice } from "@reduxjs/toolkit";
import CreateApi from "api/CreateApi";
import { fixedNumber, formatMoney } from "components/FormatNumber";
import { ErrorMessage, MiningMessage, SuccessMessage } from "components/Message";

const initialState = {
    getLoading: false,
    getView: [],
    bonusView: [],
};




export const handleGetView = () => {

    return async (dispatch) => {
        dispatch(GetLoading(true))

        try {
            let res = await CreateApi.CreateApiSSS("public/view_bonus/get",
                true);

            dispatch(GetView(res?.data?.data))
        } catch (error) {

        }

        dispatch(GetLoading(false))
    };

}

export const handleBonusView = () => {

    return async (dispatch) => {
        dispatch(GetLoading(true))

        try {
            let res = await CreateApi.CreateApiPOST("public/view_bonus/get-bonus",
                true);
            if (res?.data?.data[0]?.status == 'true') {
                MiningMessage(`You have received a reward of ${formatMoney(String(fixedNumber(res?.data?.data[0]?.amount, 2)))} VFC`)
                dispatch(BonusView(res?.data?.data))
                setTimeout(() => {
                    dispatch(handleGetView())
                }, 3000)

            }
            else {
                ErrorMessage(res?.data?.data[0]?.message)
            }


        } catch (error) {

        }

        dispatch(GetLoading(false))
    };

}




const viewSlice = createSlice({
    name: "view",
    initialState,
    reducers: {
        GetLoading(state, action) {
            return {
                ...state,
                getLoading: action.payload
            }
        },
        GetView(state, action) {
            return {
                ...state,
                getView: action.payload
            }
        },
        BonusView(state, action) {
            return {
                ...state,
                bonusView: action.payload
            }
        },
    },

});

const { reducer, actions } = viewSlice;
export const { GetLoading, GetView, BonusView } = actions;
export default reducer;
