import styled from "styled-components";

export const Banners = styled.div``;
export const Title = styled.div`
  position: absolute;
  bottom: 5%;
  width: 100%;
  text-align: center;
  > h2 {
    color: white;
    font-size: 32px;
    font-weight: bold;
    font-family: IBM Plex Sans;
  }
  > p {
    color: white;
  }
`;
