import Card from "./Card";
import RealEstate from "./RealEstate";
import VirtualMachine from "./VirtualMachine";
import { Banners, Title } from "./styles";
import banner from "assets/img/bg-nft.jpeg";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { handleGetPriceNow } from "redux/slices/wallet";
import { GetUser } from "utits/auth";

const MyNFT = () => {
  const { menuDrawer } = useSelector((state) => state.menu);
  const user = GetUser();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(handleGetPriceNow());
  }, []);
  if (!user) return <Navigate to="/auth/login" />;

  return (
    <>
      <Banners>
        <div style={{ width: "100%", position: "relative" }}>
          <img src={banner} alt="" style={{ width: "100%" }} />
          <Title data-aos="fade-up" data-aos-offset="30">
            {menuDrawer === "/my-nft" && <h2>My NFT Location</h2>}

            {menuDrawer === "VirtualMachine" && <h2>My NFT Virtual Machine</h2>}

            {menuDrawer === "card" && <h2>My NFT Card </h2>}

            <p>
              Earn your NFT items and trade them on the marketplace to make
              money
            </p>
          </Title>
        </div>
      </Banners>
      {menuDrawer === "/my-nft" && <RealEstate />}
      {menuDrawer === "card" && <Card />}
      {menuDrawer === "VirtualMachine" && <VirtualMachine />}
    </>
  );
};
export default MyNFT;
