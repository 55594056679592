import { createSlice } from "@reduxjs/toolkit";
import CreateApi from "api/CreateApi";

const initialState = {
    getLoading: false,
    listPackage: [],
    getHistory: [],
    getHistoryDirect: [],
    getHistoryType: [],
    listPackageFarming: []

};



export const handleListPackage = () => {

    return async (dispatch) => {
        dispatch(GetLoading(true))

        try {
            let res = await CreateApi.CreateApiSSS("public/package/get-package", true);
            dispatch(ListPackage(res?.data))
        } catch (error) {

        }
        dispatch(GetLoading(false))
    };

}

export const handleListPackageFarming = (page) => {
    return async (dispatch) => {
        dispatch(GetLoading(true))

        try {
            let res = await CreateApi.CreateApiSSS(`public/package/getpackageuser?limit=5&page=${page}&sort=date_start&type=asc`,
                true);
            dispatch(ListPackageFarming(res?.data))

        } catch (error) {
        }

        dispatch(GetLoading(false))
    };

}



export const handleGetHistoryDirect = (page) => {

    return async (dispatch) => {
        dispatch(GetLoading(true))

        try {
            let res = await CreateApi.CreateApiS("public/staking/hsdirect",
                {
                    'limit': 5,
                    'page': page
                }, true);
            dispatch(GetHistoryDirect(res?.data))
        } catch (error) {

        }
        dispatch(GetLoading(false))
    };

}


export const handleGetHistoryType = (page, type) => {

    return async (dispatch) => {
        dispatch(GetLoading(true))

        try {
            let res = await CreateApi.CreateApiSSS(`public/block_ico/tracking-farm?limit=5&page=${page}&type=${type}`,
                true);
            dispatch(GetHistoryType(res?.data))
        } catch (error) {

        }
        dispatch(GetLoading(false))
    };

}




const walletSlice = createSlice({
    name: "staking",
    initialState,
    reducers: {
        GetLoading(state, action) {
            return {
                ...state,
                getLoading: action.payload
            }
        },
        ListPackage(state, action) {
            return {
                ...state,
                listPackage: action.payload
            }
        },
        ListPackageFarming(state, action) {
            return {
                ...state,
                listPackageFarming: action.payload
            }
        },
        GetHistory(state, action) {
            return {
                ...state,
                getHistory: action.payload
            }
        },
        GetHistoryDirect(state, action) {
            return {
                ...state,
                getHistoryDirect: action.payload
            }
        },


        GetHistoryType(state, action) {
            return {
                ...state,
                getHistoryType: action.payload
            }
        },




    },
});

const { reducer, actions } = walletSlice;
export const { GetLoading, ListPackage, GetHistory, GetHistoryDirect, GetHistoryType, ListPackageFarming } = actions;
export default reducer;
