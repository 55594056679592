const SVG = (props) => {
  const { name = "", ...rest } = props;
  return (
    <img
      src={require(`assets/svg/${name}.svg`)}
      alt={`${name} svg icon`}
      {...rest}
    />
  );
};

export default SVG;
