import ComissionEarning from "./ComissionEarning";
import ComissionLocation from "./ComissionLocation";
import ComissionMachine from "./ComissionMachine";
import Earning from "./Earning";
import Profit from "./Profit";
import ReferralCard from "./ReferralCard";
import ReferralLocation from "./ReferralLocation";
import ReferralMachine from "./ReferralMachine";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { GetUser } from "utits/auth";

const History = () => {
  const { menuDrawer } = useSelector((state) => state.menu);
  const user = GetUser();
  if (!user) return <Navigate to="/auth/login" />;
  return (
    <>
      {menuDrawer === "/history" && <Profit />}
      {menuDrawer === "earning" && <Earning />}

      {menuDrawer === "commission-earning" && <ComissionEarning />}
      {menuDrawer === "commission-location" && <ComissionLocation />}
      {menuDrawer === "commission-machine" && <ComissionMachine />}

      {menuDrawer === "referral-card" && <ReferralCard />}
      {menuDrawer === "referral-location" && <ReferralLocation />}
      {menuDrawer === "referral-machine" && <ReferralMachine />}
    </>
  );
};
export default History;
