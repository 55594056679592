import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    listCoin: ''
};


const ortherSlice = createSlice({
    name: "listcoin",
    initialState,
    reducers: {
        realtimeCoin(state, action) {
            // localStorage.setItem('listcoin', JSON.stringify(action.payload))
            return {
                ...state,
                listCoin: action.payload
            }
        },

    },

});

const { reducer, actions } = ortherSlice;
export const { realtimeCoin } = actions;
export default reducer;
