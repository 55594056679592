import { themes } from "common/styles/theme";
import styled from "styled-components";

export const Headers = {
  Container: styled.div`
    z-index: 16;
    display: flex;
    justify-content: flex-end;
    justify-content: space-between;
    align-items: center;
    gap: 35px;
    width: 100%;
    height: 60px;
    background: rgba(0, 0, 0, 0.5);
    padding-right: 15px;
    @media (min-width: 1199px) {
      display: none;
    }

    > a {
      // position: absolute;
      // left: 7px;

      // @media (max-width: 700px) {
      //   display: none;
      // }
      @media (min-width: 1199px) {
        display: none;
      }
      > img {
        padding: 5px;
      }
    }
  `,

  ButtonPrimary: styled.button`
    ${themes.mixin.flexCenter()};
    padding: 10px 0;
    background-color: ${themes.colors.blue};
    color: ${themes.colors.white};
    font-size: ${themes.fz[12]};
    font-weight: ${themes.fw.bold};
    text-transform: uppercase;
    transition: ${themes.timer[500]};
    width: 120px;
    box-shadow: ${themes.shadow.md};
    border-radius: 4px;
    &:hover {
      transition: ${themes.timer[500]};
      background-color: ${themes.colors.white};
      color: ${themes.colors.blue};
    }
  `,
  IconWrapper: styled.div`
    width: 15px;
    height: 15px;
    ${themes.mixin.flexCenter()};
  `,
  ButtonSecond: styled.button`
      background-color: 
      border: 1px solid ${themes.colors.white};
      gap: 10px;
      padding: 10px 0;
      ${themes.mixin.flexCenter()};
      color: ${themes.colors.white};
      font-size: 10px;
      font-weight: ${themes.fw.bold};
      text-transform: uppercase;
      transition: ${themes.timer[500]};
      width: 120px;
      border-radius: 4px;
      &:hover {
        transition: ${themes.timer[500]};
        border: 1px solid ${themes.colors.blue};
        background-color: ${themes.colors.white};
        color: ${themes.colors.blue};
      }
    `,
  ButtonMenu: styled.div`
    display: none;
    @media (max-width: 1199px) {
      display: block;
    }
  `,
  IdAccount: styled.div`
    @media (min-width: 992px) {
      display: none;
    }
    color: ${themes.colors.white};
    position: relative;
    // width: 110px;
    text-align: right;
    padding: 8px 15px;
    // background: ${themes.colors.violetBtn};
    background: linear-gradient(to right, #3a1c8a, #d749fb);
    border-radius: 5px;
    font-family: "IBM Plex Sans";
    font-weight: 700;
    @media (max-width: 600px) {
      font-size: 14px;
    }
    > img {
      position: absolute;
      left: -25px;
      top: -4px;
      width: 48px;
    }
  `,
  Avatar: styled.div``,
  Logout: styled.div`
    color: ${themes.colors.white};
    font-family: "IBM Plex Sans";
    font-weight: 700;
    cursor: pointer;
    border-radius: 15px;
    padding: 8px 15px;
    background: ${themes.colors.linearBtn};
    gap: 5px;
    display: flex;
    align-items: center;
    @media (max-width: 991px) {
      font-size: 14px;
      display: none;
    }
  `,
};
