import NFTLocation from "./NFTLocation";

const RealEstate = () => {
  return (
    <>
      <NFTLocation />
    </>
  );
};
export default RealEstate;
