import styled from "styled-components";

export const Wrapper = {
  Main: styled.div`
    display: grid;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: auto auto auto;
    justify-items: center;
    gap: 0px;
    background: none;
    padding: 50px;
    border-radius: 20px;
    margin-top: 50px;
    @media (max-width: 768px) {
      padding: 20px;
      gap: 00px;
    }
    .marquee {
      width: 100%;
      @media (max-width: 767px) {
        width: 200%;
      }
    }
    @media (max-width: 767px) {
      .overlay {
        display: none !important;
      }
    }
  `,
  Logo: styled.a`
    width: 100%;
    > img {
      // width: 100px;
      // height: 30px;
      // object-fit: cover;
    }
  `,
};
